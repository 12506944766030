import { getCurrentDate } from "src/helpers";
import { IQuotationsState } from "./types";
import { createSlice } from "@reduxjs/toolkit";

const initialState: IQuotationsState = {
  quotations: [],
  data: null,
  filteredQuotations: [],
  quotationFilters: [],
  quotationValues: {
    source_location: "",
    source_country: "",
    source_port: "",
    destination_location: "",
    destination_country: "",
    destination_port: "",
    // date: new Date(),
    date: getCurrentDate(),
    mode_of_transport: "ocean",
    quotation_type: "",
    service_type: "",
    customer_id: null,
    total_cargo_dimensions: false,
    is_cm: true,
    reference_no: "",
    cargo_item_specs: [
      {
        cm_kg: 1,
        colli_type: "Pallet",
        quantity: 0,
        length: 0,
        width: 0,
        height: 0,
        volume: 0,
        weight: 0,
        stackable: 1,
      },
    ],
    charges: {
      "pre-carriage": [],
      origin: [],
      "ocean-freight": [],
      destination: [],
    },
  },
  isButtonDisabled: false,
  selectedQuotations: [],
};

const quotationsSlice = createSlice({
  name: "quotations",
  initialState,
  reducers: {
    setQuotations(state, action) {
      state.quotations = action.payload;
    },
    setData(state, action) {
      state.data = action.payload;
    },
    setFilteredQuotations(state, action) {
      state.filteredQuotations = action.payload;
    },
    setQuotationFilters(state, action) {
      state.quotationFilters = action.payload;
    },
    setQuotationValues(state, action) {
      state.quotationValues = action.payload;
    },
    setSelectedQuotations(state, action) {
      state.selectedQuotations = action.payload;
    },
    setIsButtonDisabled(state, action) {
      state.isButtonDisabled = action.payload;
    },
    updateQuotationCharges(state, action) {
      const findIndex = state.filteredQuotations.findIndex(
        (quotation: any) => quotation.id == action.payload.subQuotationId,
      );
      if (findIndex !== -1) {
        state.filteredQuotations[findIndex].categorised_charges =
          action.payload.chargesData;
      }
    },
  },
});

export default quotationsSlice.reducer;
export const {
  setQuotations,
  setData,
  setFilteredQuotations,
  setQuotationFilters,
  setQuotationValues,
  setSelectedQuotations,
  setIsButtonDisabled,
  updateQuotationCharges,
} = quotationsSlice.actions;
