import React, { useState, useEffect } from "react";
import PanelLayout from "src/components/layouts/panel-layout";
import QuotationTabs from "./elements/quotation-tabs";
import SRPreview from "src/components/layouts/sr-preview";
import { IBreadcrumbItem, TITLES } from "src/types";
import CargoShipperSuccess from "./elements/cargo-shipper-success";
import { useAppSelector } from "src/store/hook";
import { quotationsSelector } from "src/store/features/quotations/selectors";
import { useSearchParams } from "react-router-dom";
import SRPreviewFcl from "src/components/layouts/sr-preview-fcl";
import { useQuotationsContext } from "src/context/quotations-context";
import { useQuotationsContextFcl } from "src/context/quotations-context-fcl";

export const Quotations = () => {
  const { formikQuotation } = useQuotationsContext();
  const { formikQuotationFcl } = useQuotationsContextFcl();

  const breadcrumbsData: IBreadcrumbItem[] = [
    {
      title: "Quotations",
      isDisabled: true,
    },
    {
      title: "Fill Details",
    },
  ];

  const [data, setData] = useState(breadcrumbsData);

  const [search] = useSearchParams();
  const title = search.get("tab");

  const { data: quotationData } = useAppSelector(quotationsSelector);

  const APP_TITLE = process.env.REACT_APP_TITLE;
  const TAB_TITLE = TITLES.QUOTATIONS;

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    document.title = TAB_TITLE + " | " + APP_TITLE;
  }, [data]);

  useEffect(() => {
    document.title = TAB_TITLE + " | " + APP_TITLE;
  }, []);

  const tabChanged = (title: any) => {
    setData([
      {
        title: "Quotations",
        isDisabled: true,
      },
      {
        title:
          title === "Generate Quotations"
            ? "Fill Details"
            : title || "Fill Details",
      },
    ]);
  };

  useEffect(() => {
    tabChanged(title);
  }, [quotationData]);

  return (
    <PanelLayout breadcrumbsData={data}>
      <>
        <div
          className={
            formikQuotationFcl?.isSubmitting || formikQuotation?.isSubmitting
              ? ""
              : "hidden"
          }
        >
          <CargoShipperSuccess toggle={setSuccess} />
        </div>
        <div
          className={
            formikQuotationFcl?.isSubmitting || formikQuotation?.isSubmitting
              ? "hidden"
              : ""
          }
        >
          <QuotationTabs clicked={success} tabChanged={tabChanged} />
          {/* <SRPreview onSuccess={(val) => setSuccess(val)} /> */}
          {formikQuotation?.values?.service_type == "lcl" && (
            <SRPreview onSuccess={(val) => setSuccess(val)} />
          )}
          {formikQuotation?.values?.service_type == "fcl" && <SRPreviewFcl />}
        </div>
      </>
    </PanelLayout>
  );
};
