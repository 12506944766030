import { useState, useEffect } from "react";
import { checkValidCharges } from "src/helpers";

function useValidCharges(chargesData: any) {
  const [isValidCharges, setIsValidCharges] = useState(true);

  useEffect(() => {
    const validateCharges = () => {
      return checkValidCharges(chargesData);
    };

    setIsValidCharges(validateCharges());
  }, [chargesData]);

  return isValidCharges;
}

export default useValidCharges;
