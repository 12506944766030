import React, { useEffect, useState } from "react";
import CustomDatePicker from "src/components/atoms/date-picker";
import LocationPicker from "src/components/atoms/location-picker";
import TextInput from "src/components/atoms/text-input";
import { useQuotationsContext } from "src/context/quotations-context";
import CargoSpecFcl from "./cargo-specs-fcl";
import { useQuotationsContextFcl } from "src/context/quotations-context-fcl";
import LazyPortsSelector from "src/components/atoms/lazy-port-selecter";
import TermsInputFcl from "./terms-input-fcl";
import ChargesTableFcl from "src/pages/customer/quotation-results/elements/charges-table-fcl";
import {
  checkHasValidQuotationItems,
  isCustomQuotationUpdated,
} from "src/helpers";

const values = [""];
const data = {
  columns: [{ container_type: "40HC", quantity: 1 }],
  charges: [
    {
      id: 1,
      category: "Ocean Freight",
      total: 900,
      details: [
        {
          id: 1,
          name: "",
          values: values,
          isEditable: true,
        },
      ],
      isEditable: true,
    },
    {
      id: 2,
      category: "Charges payable at export",
      total: 800,
      details: [
        {
          id: 1,
          name: "",
          values: values,
          isEditable: true,
        },
      ],
      isEditable: true,
    },
    {
      id: 3,
      category: "Charges payable as per freight",
      total: 400,
      details: [
        {
          id: 1,
          name: "",
          values: values,
          isEditable: true,
        },
      ],
      isEditable: true,
    },
  ],
};

const FclQuotationComponent = ({ postCodesData }: { postCodesData: any }) => {
  const { formikQuotation } = useQuotationsContext();
  const { formikQuotationFcl } = useQuotationsContextFcl();

  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id") ?? "";

  const [chargesData, setChargesData] = useState<any>(
    formikQuotationFcl?.values?.chargesData,
  );

  console.log(formikQuotationFcl, "formikQuotationFcl>>>>>>");

  useEffect(() => {
    formikQuotationFcl.setFieldValue("chargesData", chargesData);
  }, [chargesData]);

  useEffect(() => {
    const cargoItemSpecs: any[] = formikQuotationFcl.values.cargo_item_specs;

    if (cargoItemSpecs?.length > 0) {
      // Extract container types
      const containerTypes: any[] = cargoItemSpecs
        .filter((item: any) => item.container_type)
        .map((item: any) => ({
          container_type: item.container_type,
          quantity: item?.quantity,
        }));

      // Update chargesData columns and synchronize values
      setChargesData((prevChargesData: any) => {
        const updatedColumns: any[] = containerTypes; // Set columns to container types
        const updatedCharges: any[] = prevChargesData.charges.map(
          (charge: any) => ({
            ...charge,
            details: charge.details.map((detail: any) => ({
              ...detail,
              values: updatedColumns.map(
                (_, index) => detail.values[index] || "",
              ), // Ensure values match columns
            })),
          }),
        );

        return {
          ...prevChargesData,
          columns: updatedColumns,
          charges: updatedCharges,
        };
      });
    }
  }, [formikQuotationFcl.values.cargo_item_specs]);

  useEffect(() => {
    console.log(
      "formikQuotationFcl.values.cargo_item_specs",
      formikQuotationFcl.values.cargo_item_specs,
    );
  }, [formikQuotationFcl.values.cargo_item_specs]);

  const clearLocations = () => {
    formikQuotationFcl.setFieldValue("pickup_location", "");
    formikQuotationFcl.setFieldValue("destination_location", "");
  };

  useEffect(() => {
    if (
      !formikQuotationFcl.values.origin_port ||
      !formikQuotationFcl.values.destination_port
    ) {
      formikQuotationFcl?.setFieldValue("terms", "2");
    }
  }, [
    formikQuotationFcl.values.origin_port,
    formikQuotationFcl.values.destination_port,
  ]);

  return (
    <>
      <span className="hidden"> Routing Details </span>
      <div className="mb-24 px-24 pb-16">
        <h3 className="pb-12 text-18 font-semibold leading-28 text-primary-800">
          Routing Details
        </h3>
        <div className="grid grid-cols-12 gap-x-5">
          <div className="col-span-12 xl:col-span-5">
            <TextInput
              name="reference_no"
              type="text"
              label="Reference No."
              placeholder="Type here.."
              classes={{
                inputClassName: "normal-input",
              }}
              value={
                formikQuotationFcl?.values?.reference_no ||
                formikQuotation?.value?.reference_no
              }
              onChange={(e: any) => {
                formikQuotation?.setFieldValue("reference_no", e.target.value);
                formikQuotationFcl?.setFieldValue(
                  "reference_no",
                  e.target.value,
                );
              }}
              disabled={Boolean(id)}
              // required={true}
            />
          </div>
          <div className="col-span-12 xl:col-span-5">
            <CustomDatePicker
              name="date"
              label="Cargo Ready date"
              containerClassName="cargo-ready-date-picker"
              value={
                formikQuotation?.values?.date ||
                formikQuotationFcl?.values?.date
              }
              onChange={(date: any, dateString: any) => {
                formikQuotation?.setFieldValue("date", date);
                formikQuotationFcl?.setFieldValue("date", date);
              }}
              errorMessage={
                formikQuotation?.errors?.date ||
                formikQuotationFcl?.errors?.date
              }
              showError={
                formikQuotation?.touched?.date ||
                formikQuotationFcl?.touched?.date
              }
              required={true}
            />
          </div>
        </div>
      </div>

      {/* Port to port */}
      <div className="mb-24 grid grid-cols-12 gap-5 px-24 pb-16">
        {/*  Origin Port & Destination Port & Terms */}
        <div className="col-span-12 xl:col-span-4">
          <LazyPortsSelector
            placeholder={
              isCustomQuotationUpdated(formikQuotation)
                ? "Enter a Port"
                : "Select a Port"
            }
            name="origin_port"
            label="Origin Port"
            value={formikQuotationFcl?.values?.origin_port}
            onChange={(value: string) => {
              isCustomQuotationUpdated(formikQuotation) &&
                formikQuotationFcl?.setFieldValue("origin_port", value);
            }}
            onSelect={(value: string) => {
              formikQuotationFcl?.setFieldValue("origin_port", value);
              clearLocations();
            }}
            required={true}
            allowCustomValue={isCustomQuotationUpdated(formikQuotation)}
            filterValue={formikQuotationFcl?.values?.destination_port}
          />
        </div>
        <div className="col-span-12 xl:col-span-4">
          <LazyPortsSelector
            placeholder={
              isCustomQuotationUpdated(formikQuotation)
                ? "Enter a Port"
                : "Select a Port"
            }
            name="destination_port"
            label="Destination Port"
            value={formikQuotationFcl?.values?.destination_port}
            onChange={(value: string) => {
              isCustomQuotationUpdated(formikQuotation) &&
                formikQuotationFcl?.setFieldValue("destination_port", value);
            }}
            onSelect={(value: string) => {
              formikQuotationFcl?.setFieldValue("destination_port", value);
              clearLocations();
            }}
            required={true}
            allowCustomValue={isCustomQuotationUpdated(formikQuotation)}
            filterValue={formikQuotationFcl?.values?.origin_port}
          />
        </div>
        {formikQuotationFcl?.values?.quotation_type === "custom" ? (
          <div className="col-span-12 xl:col-span-4">
            <TermsInputFcl />
          </div>
        ) : (
          formikQuotationFcl?.values?.origin_port &&
          formikQuotationFcl?.values?.destination_port && (
            <div className="col-span-12 xl:col-span-4">
              <TermsInputFcl />
            </div>
          )
        )}
      </div>

      {/* Door to port */}
      {formikQuotationFcl?.values?.terms?.includes("1") && (
        <div className="mb-24 grid grid-cols-12 gap-5 px-24 pb-16">
          <div className="col-span-12 xl:col-span-4">
            <LocationPicker
              placeholder="Enter Pickup Location"
              name="pickup_location"
              label="Pickup Location"
              value={formikQuotationFcl?.values?.pickup_location}
              onChange={(value: string) => {
                // isCustomQuotationUpdated(formikQuotation) &&
                formikQuotationFcl.setFieldValue("pickup_location", value);
              }}
              onSelect={(value: string) => {
                formikQuotationFcl.setFieldValue("pickup_location", value);
              }}
              postcodes={postCodesData || []} // Pass postcodes data here
              countryCode={formikQuotationFcl?.values?.origin_port?.substring(
                0,
                2,
              )}
              disabled={!Boolean(formikQuotationFcl.values.origin_port)}
              required={true}
            />
          </div>
        </div>
      )}

      {/* Door to door */}
      {formikQuotationFcl?.values?.terms?.includes("3") && (
        <div className="mb-24 grid grid-cols-12 gap-5 px-24 pb-16">
          <div className="col-span-12 xl:col-span-4">
            <LocationPicker
              placeholder="Enter Pickup Location"
              name="pickup_location"
              label="Pickup Location"
              value={formikQuotationFcl?.values?.pickup_location}
              onChange={(value: string) => {
                // isCustomQuotationUpdated(formikQuotation) &&
                formikQuotationFcl.setFieldValue("pickup_location", value);
              }}
              onSelect={(value: string) => {
                formikQuotationFcl.setFieldValue("pickup_location", value);
              }}
              postcodes={postCodesData || []} // Pass postcodes data here
              countryCode={formikQuotationFcl?.values?.origin_port?.substring(
                0,
                2,
              )}
              disabled={!Boolean(formikQuotationFcl.values.origin_port)}
              required={true}
            />
          </div>
          <div className="col-span-12 xl:col-span-4">
            <LocationPicker
              placeholder="Enter Destination Location"
              name="destination_location"
              label="Destination Location"
              value={formikQuotationFcl?.values?.destination_location}
              onChange={(value: string) => {
                // isCustomQuotationUpdated(formikQuotation) &&
                formikQuotationFcl.setFieldValue("destination_location", value);
              }}
              onSelect={(value: string) => {
                formikQuotationFcl.setFieldValue("destination_location", value);
              }}
              postcodes={postCodesData || []}
              countryCode={formikQuotationFcl?.values?.destination_port?.substring(
                0,
                2,
              )}
              disabled={!Boolean(formikQuotationFcl.values.destination_port)}
              required={true}
            />
          </div>
        </div>
      )}

      {/* Port to door */}
      {formikQuotationFcl?.values?.terms?.includes("4") && (
        <div className="mb-24 grid grid-cols-12 gap-5 px-24 pb-16">
          <div className="col-span-12 xl:col-span-4"></div>
          <div className="col-span-12 xl:col-span-4">
            <LocationPicker
              placeholder="Enter Destination Location"
              name="destination_location"
              label="Destination Location"
              value={formikQuotationFcl?.values?.destination_location}
              onChange={(value: string) => {
                // isCustomQuotationUpdated(formikQuotation) &&
                formikQuotationFcl.setFieldValue("destination_location", value);
              }}
              onSelect={(value: string) => {
                formikQuotationFcl.setFieldValue("destination_location", value);
              }}
              postcodes={postCodesData || []}
              countryCode={formikQuotationFcl?.values?.destination_port?.substring(
                0,
                2,
              )}
              disabled={!Boolean(formikQuotationFcl.values.destination_port)}
            />
          </div>
        </div>
      )}

      <div className="mb-24 border-t border-border px-24">
        <h2 className="my-12 text-18 font-semibold leading-28 text-primary-800">
          How will your cargo be shipped?
        </h2>
        <CargoSpecFcl />

        {formikQuotation?.values?.quotation_type == "custom" &&
          checkHasValidQuotationItems(
            formikQuotationFcl?.values?.cargo_item_specs,
          ) && (
            <>
              <h1 className="my-16 text-24 font-semibold">Pricing Details</h1>
              <ChargesTableFcl
                chargesData={chargesData}
                setChargesData={setChargesData}
                showEditButton={false}
                defaultEditable={true}
                stickyColClassName="bg-[#FBFBFB]"
                isEditMode={true}
              />
            </>
          )}
      </div>
    </>
  );
};

export default FclQuotationComponent;
