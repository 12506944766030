import React from "react";
import { IButtonStyles, ICustomButton } from "./types";
import { Button } from "antd";
import "./style.css";

const CustomButton = ({
  className = "",
  loading = false,
  onClick,
  onChange,
  disabled = false,
  block = false,
  children,
  size = "large",
  htmlType = "button",
  variant = "primary",
  title = "",
  icon, // New prop for icon
}: ICustomButton) => {
  const buttonStyles: IButtonStyles = {
    primary:
      "primary-btn flex items-center justify-center bg-primary-500-base transition duration-[0.4s] hover:bg-primary-600 border-none !px-20 !py-10 ",
    "primary-icon-btn":
      "primary-btn primary-icon-btn flex items-center justify-center bg-primary-500-base transition duration-[0.4s] hover:bg-primary-600 border-none !px-16 !py-10 ",
    "secondary-icon-btn":
      "secondary-btn secondary-icon-btn flex items-center justify-center border border-gray-300 transition duration-[0.4s] bg-white hover:bg-primary-50 !px-16 !py-10 ",
    secondary:
      "secondary-btn !flex !items-center !justify-center border border-gray-300 transition duration-[0.4s] bg-white hover:!bg-primary-50 !px-20 !py-10 ",
    custom: "",
    delete:
      "delete-btn bg-white border-error-danger-500-base transition duration-[0.4s] hover:border-error-danger-500-base hover:bg-error-danger-50 px-20 py-10",
    infolink:
      "info-link  flex items-center justify-center border-none !px-0 !py-0 !transition-none",
    warning:
      "flex items-center text-white !bg-warning-600 transition !hover:bg-warning-500 hover:text-black border-none !px-22 !py-10",
    danger:
      "bg-error-danger-900 text-white border-error-danger-500-base transition duration-[0.4s]  !border-transparent hover:!bg-error-danger-700 px-20 py-10",
    success:
      "bg-success-650 text-white border-error-danger-500-base transition duration-[0.4s]  !border-transparent hover:!bg-success-550 px-20 py-10 disabled:pointer-events-none disabled:bg-success-650 disabled:text-white",
  };

  return (
    <Button
      type="default"
      className={`custom-button ${buttonStyles[variant]} ${className} `}
      size={size}
      loading={loading}
      onClick={onClick}
      onChange={onChange}
      htmlType={htmlType}
      disabled={disabled}
      block={block}
      icon={icon}
      title={title}
    >
      {loading ? "Loading..." : children}
    </Button>
  );
};

export default CustomButton;
