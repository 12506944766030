import React, { useEffect, useState } from "react";
import BackButton from "src/components/atoms/back-button";
import ContentWrapper from "src/components/layouts/content-wrapper";
import { TITLES } from "src/types";
import { formatDate, showToast, statusColor } from "src/utils";
import useGetBookingDetails from "./useGetBookingDetails";
import { useParams } from "react-router-dom";
import AppLoader from "src/components/atoms/app-loader/app-loader.component";
import { ENDPOINTS } from "src/store/endpoints";
import { setLoading } from "src/store/features/alerts";
import { useAppDispatch } from "src/store/hook";
import useUser from "src/hooks/useUser";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import {
  calculateFinalTotalPrice,
  formatContainerTypeAndSizeForPreview,
  formatQuotationReference,
  getTotalQuantity,
  getTotalWeight,
} from "src/helpers";
import PanelLayout from "src/components/layouts/panel-layout";

interface Detail {
  id: string;
  title: string;
  value: JSX.Element | string;
}
interface DataType {
  bookingDetails: Detail[];
  shipperDetails: Detail[];
  packageDetails: Detail[];
}

export default function BookingDetails() {
  const { id } = useParams();
  const { data: fetchedData } = useGetBookingDetails(id);
  const [data, setData] = useState<DataType | null>(null);

  useEffect(() => {
    if (!fetchedData) return;

    console.log(fetchedData, "fetchedData>>>");

    if (fetchedData?.service_type == "lcl") {
      setData((prev) => ({
        ...prev,
        bookingDetails: [
          {
            id: "bd0",
            title: "Booking Reference no.",
            value: fetchedData?.main_quotation
              ? formatQuotationReference(fetchedData?.main_quotation)
              : fetchedData.reference_no,
          },
          {
            id: "bd1",
            title: "Creation Date", // created_at
            value: <span>{formatDate(fetchedData.date)}</span>,
          },
          {
            id: "bd2",
            title: "Status", // status
            value: (
              <div
                className={`w-fit rounded-2xl px-8 py-2 text-12 font-medium capitalize leading-18 ${statusColor.get("booked")}`}
              >
                {fetchedData.status}
              </div>
            ),
          },
          {
            id: "bd3",
            title: "Price",
            value: (
              <span>
                {" "}
                {fetchedData.currency} {fetchedData.price}
              </span>
            ),
          },
          {
            id: "bd4",
            title: "Payment Receipt",
            value: "Download pdf",
          },
        ],

        shipperDetails: [
          // {
          //   id: "sd0",
          //   title: "-",
          //   value: "Pillow office chairs",
          // },
          {
            id: "sd1",
            title: "Consignee Company",
            value: (
              <span>{fetchedData?.quotation_confirm?.shipper_name || "-"}</span>
            ),
          },
          {
            id: "sd2",
            title: "PO# or Shipper Reference ",
            value: (
              <span>
                {fetchedData?.quotation_confirm?.shipper_reference || "-"}
              </span>
            ),
          },
          {
            id: "sd3",
            title: "Contact Person",
            value: (
              <span>
                {fetchedData?.quotation_confirm?.contact_person || "-"}
              </span>
            ),
          },
          {
            id: "sd4",
            title: "Phone Number",
            value: (
              <span>
                {fetchedData?.quotation_confirm?.contact_phone || "-"}
              </span>
            ),
          },
          {
            id: "sd5",
            title: "Additional Information",
            value: (
              <span>
                {fetchedData?.quotation_confirm?.additional_info || "-"}
              </span>
            ),
          },
          {
            id: "sd6",
            title: "Address",
            value: (
              <span>{fetchedData?.quotation_confirm?.address || "-"}</span>
            ),
          },
          {
            id: "sd7",
            title: "Email",
            value: <span>{fetchedData?.quotation_confirm?.email || "-"}</span>,
          },
          {
            id: "sd8",
            title: "Uploaded File",
            value: (
              <div className="flex flex-col gap-3">
                {fetchedData?.quotation_confirm?.files?.map((item: any) => (
                  <a
                    href={item.url}
                    key={item.url}
                    className="text-blue-600 underline underline-offset-4 hover:text-blue-800"
                  >
                    {item.name}
                  </a>
                )) || "-"}
              </div>
            ),
          },
        ],

        packageDetails: [
          {
            id: "pd0",
            title: "Carrier",
            value: fetchedData.mode_of_transport?.toUpperCase(),
          },
          {
            id: "pd1",
            title: "Origin", // source_location (nullable)
            value: fetchedData.source_location ?? "-",
          },
          {
            id: "pd2",
            title: "Destination", // destination_location (nullable)
            value: fetchedData.destination_location ?? "-",
          },
          {
            id: "pd3",
            title: "Service Type", // service_type
            value: fetchedData.service_type
              ? String(fetchedData.service_type).toUpperCase()
              : "-",
          },
          {
            id: "pd4",
            title: "Quantity",
            value: fetchedData.quantity,
          },
          {
            id: "pd5",
            title: "Weight",
            value: fetchedData.weight + "kg",
          },
          {
            id: "pd6",
            title: "Volume",
            value: fetchedData.volume + "M³",
          },
          {
            id: "pd7",
            title: "Stackable",
            value: `${fetchedData?.totalStackableItems} of ${fetchedData?.quantity}`,
          },
        ],
      }));
    } else if (fetchedData?.service_type == "fcl") {
      setData((prev) => ({
        ...prev,
        bookingDetails: [
          {
            id: "bd0",
            title: "Booking Reference no.",
            value: fetchedData?.main_quotation
              ? formatQuotationReference(fetchedData?.main_quotation)
              : fetchedData.reference_no,
          },
          {
            id: "bd1",
            title: "Creation Date", // created_at
            value: <span>{formatDate(fetchedData.created_at)}</span>,
          },
          {
            id: "bd2",
            title: "Status", // status
            value: (
              <div
                className={`w-fit rounded-2xl px-8 py-2 text-12 font-medium capitalize leading-18 ${statusColor.get("booked")}`}
              >
                {fetchedData.status}
              </div>
            ),
          },
          {
            id: "bd3",
            title: "Price",
            value: (
              <span>
                {" "}
                {fetchedData?.categorized_charges &&
                  calculateFinalTotalPrice(fetchedData?.categorized_charges)}
              </span>
            ),
          },
          {
            id: "bd4",
            title: "Payment Receipt",
            value: "Download pdf",
          },
        ],

        shipperDetails: [
          // {
          //   id: "sd0",
          //   title: "-",
          //   value: "Pillow office chairs",
          // },
          {
            id: "sd1",
            title: "Consignee Company",
            value: (
              <span>{fetchedData?.quotation_confirm?.shipper_name || "-"}</span>
            ),
          },
          {
            id: "sd2",
            title: "PO# or Shipper Reference ",
            value: (
              <span>
                {fetchedData?.quotation_confirm?.shipper_reference || "-"}
              </span>
            ),
          },
          {
            id: "sd3",
            title: "Contact Person",
            value: (
              <span>
                {fetchedData?.quotation_confirm?.contact_person || "-"}
              </span>
            ),
          },
          {
            id: "sd4",
            title: "Phone Number",
            value: (
              <span>
                {fetchedData?.quotation_confirm?.contact_phone || "-"}
              </span>
            ),
          },
          {
            id: "sd5",
            title: "Additional Information",
            value: (
              <span>
                {fetchedData?.quotation_confirm?.additional_info || "-"}
              </span>
            ),
          },
          {
            id: "sd6",
            title: "Address",
            value: (
              <span>{fetchedData?.quotation_confirm?.address || "-"}</span>
            ),
          },
          {
            id: "sd7",
            title: "Email",
            value: <span>{fetchedData?.quotation_confirm?.email || "-"}</span>,
          },
          {
            id: "sd8",
            title: "Uploaded File",
            value: (
              <div className="flex flex-col gap-3">
                {fetchedData?.quotation_confirm?.files?.map((item: any) => (
                  <a
                    href={item.url}
                    key={item.url}
                    className="text-blue-600 underline underline-offset-4 hover:text-blue-800"
                  >
                    {item.name}
                  </a>
                )) || "-"}
              </div>
            ),
          },
        ],

        packageDetails: [
          {
            id: "pd0",
            title: "Carrier",
            value: fetchedData.mode_of_transport?.toUpperCase(),
          },
          {
            id: "pd1",
            title: "Origin", // source_location (nullable)
            value: fetchedData?.main_quotation?.source_country ?? "-",
          },
          {
            id: "pd2",
            title: "Destination", // destination_location (nullable)
            value: fetchedData.destination_location ?? "-",
          },
          {
            id: "pd3",
            title: "Service Type", // service_type
            value: fetchedData.service_type
              ? String(fetchedData.service_type).toUpperCase()
              : "-",
          },
          {
            id: "pd4",
            title: "Quantity",
            // value: getTotalQuantity(fetchedData.container_items),
            value: formatContainerTypeAndSizeForPreview(
              fetchedData.container_items,
            ),
          },
          {
            id: "pd5",
            title: "Weight",
            value: getTotalWeight(fetchedData.container_items) + "kg",
          },
        ],
      }));
    }
  }, [fetchedData]);

  const APP_TITLE = process.env.REACT_APP_TITLE;
  const TAB_TITLE = TITLES.BOOKING_DETAILS;
  const baseUrl = process.env.REACT_APP_API_URL;
  const dispatch = useAppDispatch();
  const user = useUser();
  useEffect(() => {
    document.title = TAB_TITLE + " | " + APP_TITLE;
  }, []);

  const downloadPdf = async () => {
    const downloadBookingEndpoint =
      ENDPOINTS.ADMIN.DOWNLOAD_BOOKING + id + "/pdf";
    try {
      dispatch(setLoading(true));
      const token = user?.access_token;
      const downloadUrl = baseUrl + downloadBookingEndpoint;
      const response = await fetch(downloadUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const contentType = response.headers.get("content-type") ?? "";
        const filename = `${fetchedData?.source_location}_to${fetchedData?.destination_location}_booking.pdf`;
        const message = "The Pdf has been successfully downloaded";

        const blob = new Blob([await response.blob()], {
          type: contentType,
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        showToast(TOASTR_TYPES.SUCCESS, message);
      } else {
        showToast(TOASTR_TYPES.ERROR, `Enable to download`);
      }
    } catch (error: any) {
      showToast(TOASTR_TYPES.ERROR, `Enable to download`);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <PanelLayout
      breadcrumbsData={[
        { title: "Customer Management" },
        { title: "Booking Details" },
      ]}
    >
      <ContentWrapper>
        <BackButton />
        <h1 className="my-10 text-24 font-bold">Booking Details</h1>

        {data ? (
          <div className="rounded-[0.625rem] border border-border">
            <DetailsSection title="Booking Details">
              {data?.bookingDetails?.map(({ id, title, value }) => (
                <DetailItem key={id} title={title}>
                  {value === "Download pdf" ? (
                    <button
                      className="text-blue-600 underline underline-offset-4 hover:text-blue-800"
                      onClick={downloadPdf}
                    >
                      {value}
                    </button>
                  ) : (
                    value
                  )}
                </DetailItem>
              ))}
            </DetailsSection>

            {/* ===== Shipper Details ===== */}
            <DetailsSection title="Shipper Details">
              {data.shipperDetails.map(({ id, title, value }) => (
                <DetailItem key={id} title={title}>
                  {value}
                </DetailItem>
              ))}
            </DetailsSection>

            {/* ===== Package Details ===== */}
            <DetailsSection title="Package Details">
              {data.packageDetails.map(({ id, title, value }) => (
                <DetailItem key={id} title={title}>
                  {value}
                </DetailItem>
              ))}
            </DetailsSection>
          </div>
        ) : (
          <AppLoader />
        )}
      </ContentWrapper>
    </PanelLayout>
  );
}

/* ========== Details Section Component ========== */

interface DetailElement {
  title: string;
  children: React.ReactNode;
}

const DetailsSection = ({ title, children }: DetailElement) => {
  return (
    <>
      <div className="border-b border-border px-24 pb-12 pt-24">
        <h2 className="text-18 font-semibold">{title}</h2>
      </div>

      <div className="grid grid-cols-4 gap-x-12 gap-y-6 px-24 py-20 font-medium">
        {children}
      </div>
    </>
  );
};

/* ========== DetailItem Component ========== */

const DetailItem = ({ title, children }: DetailElement) => {
  return (
    <div>
      <div className="mb-4 text-14 leading-20 text-slate-600">{title}</div>
      <div style={{ wordWrap: "break-word" }}>{children}</div>
    </div>
  );
};
