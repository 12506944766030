import * as yup from "yup";

export const createQuotationFclSchema = ({ terms }: any) => {
  let schema: any = yup.object().shape({
    date: yup.string().trim().required("Please select cargo ready date"),
    service_type: yup.string().trim().required(""),
    quotation_type: yup.string().trim().required(""),
    origin_port: yup.string().trim().required("Please select origin port"),
    destination_port: yup
      .string()
      .trim()
      .required("Please select destination port"),
    pickup_location: yup
      .string()
      .trim()
      .required("Please select pickup location"),
    destination_location: yup
      .string()
      .trim()
      .required("Please select destination location"),
    cargo_item_specs: yup.array().of(
      yup.object().shape({
        quantity: yup
          .string()
          .required("No. of containers is required")
          .test(
            "is-valid-number",
            "Quantity must be a non-zero number or decimal",
            (value) => {
              const num = parseFloat(value);
              return !isNaN(num) && num !== 0;
            },
          ),
        container_type: yup.string().required("Container type is required"),
        weight: yup
          .string()
          .required("Weight is required")
          .test(
            "is-valid-number",
            "Weight must be a non-zero number or decimal",
            (value) => {
              const num = parseFloat(value);

              // Check if it's a valid number, non-zero, and if it's a decimal, it must have digits after the decimal point
              return (
                !isNaN(num) &&
                num !== 0 &&
                !(value.includes(".") && value.split(".")[1] === "")
              );
            },
          ),
      }),
    ),
  });

  // Modify schema based on the value of terms
  if (terms === "1") {
    // Make destination_location optional if terms is "1"
    schema = schema.shape({
      destination_location: yup.string().trim().notRequired(),
    });
  } else if (terms === "2") {
    // Make both pickup_location and destination_location optional if terms is "2"
    schema = schema.shape({
      pickup_location: yup.string().trim().notRequired(),
      destination_location: yup.string().trim().notRequired(),
    });
  } else if (terms === "3") {
    // Make both pickup_location and destination_location required if terms is "3"
    schema = schema.shape({
      pickup_location: yup
        .string()
        .trim()
        .required("Please select pickup location"),
      destination_location: yup
        .string()
        .trim()
        .required("Please select destination location"),
    });
  } else if (terms === "4") {
    // Make pickup_location optional if terms is "4"
    schema = schema.shape({
      pickup_location: yup.string().trim().notRequired(),
    });
  }

  return schema;
};
