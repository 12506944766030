import React, { useEffect, useState } from "react";
import CustomSelect from "src/components/atoms/custom-select";
import { useQuotationsContextFcl } from "src/context/quotations-context-fcl";
import {
  getSelectedContainerTypes,
  removeSelectedContainerTypeFromOptions,
} from "src/helpers";

export const CONTAINER_TYPES = [
  {
    key: 1,
    label: <span>Dry</span>,
    title: "Dry",
    options: [
      {
        key: "20DRY",
        label: <span>(20DRY) 20 Dry Standard</span>,
        value: "20DRY",
        sizeTypeId: 1,
      },
      {
        key: "40DRY",
        label: <span>(40DRY) 40 Dry Standard</span>,
        value: "40DRY",
        sizeTypeId: 2,
      },
      {
        key: "40HC",
        label: <span>(40HC) 40 Dry High Cube</span>,
        value: "40HC",
        sizeTypeId: 3,
      },
      {
        key: "45HC",
        label: <span>(45HC) 45 Dry High Cube</span>,
        value: "45HC",
        sizeTypeId: 7,
      },
    ],
  },
  {
    key: 2,
    label: <span>Reefer</span>,
    title: "Reefer",
    options: [
      {
        key: "20RF",
        label: <span>(20RF) 20 Reefer Standard</span>,
        value: "20RF",
        sizeTypeId: 4,
      },
      {
        key: "40RF",
        label: <span>(40RF) 40 Reefer Standard</span>,
        value: "40RF",
        sizeTypeId: 5,
      },
      {
        key: "40HR",
        label: <span>(40HR) 40 Reefer High Cube</span>,
        value: "40HR",
        sizeTypeId: 6,
      },
      {
        key: "45HR",
        label: <span>(45HR) 45 Reefer High Cube</span>,
        value: "45HR",
        sizeTypeId: 8,
      },
    ],
  },
  {
    key: 3,
    label: <span>Flat Rack</span>,
    title: "Flat Rack",
    options: [
      {
        key: "20FL",
        label: <span>(20FL) 20 Flat Rack</span>,
        value: "20FL",
        sizeTypeId: 11,
      },
      {
        key: "40FL",
        label: <span>(40FL) 40 Flat Rack</span>,
        value: "40FL",
        sizeTypeId: 12,
      },
      {
        key: "40HF",
        label: <span>(40HF) 40 Flat High Cube</span>,
        value: "40HF",
        sizeTypeId: 15,
      },
    ],
  },
  {
    key: 4,
    label: <span>Open Top</span>,
    title: "Open Top",
    options: [
      {
        key: "20OT",
        label: <span>(20OT) 20 Open Top</span>,
        value: "20OT",
        sizeTypeId: 9,
      },
      {
        key: "40OT",
        label: <span>(40OT) 40 Open Top</span>,
        value: "40OT",
        sizeTypeId: 10,
      },
      {
        key: "40HO",
        label: <span>(40HO) 40 Open High Cube</span>,
        value: "40HO",
        sizeTypeId: 14,
      },
    ],
  },
  {
    key: 5,
    label: <span>Tank</span>,
    title: "Tank",
    options: [
      {
        key: "20TK",
        label: <span>(20TK) 20 Tank</span>,
        value: "20TK",
        sizeTypeId: 13,
      },
    ],
  },
];

const ContainerTypeComponent = ({ idx }: any) => {
  const { formikQuotationFcl } = useQuotationsContextFcl();
  const [containerTypes, setContainerTypes] = useState(CONTAINER_TYPES);
  const [selectedContainerTypes, setSelectedContainerTypes] = useState([]);

  useEffect(() => {
    setSelectedContainerTypes(
      getSelectedContainerTypes(
        formikQuotationFcl?.values?.cargo_item_specs,
        idx,
      ),
    );
  }, [formikQuotationFcl?.values?.cargo_item_specs]);

  useEffect(() => {
    const filtered = removeSelectedContainerTypeFromOptions(
      CONTAINER_TYPES,
      selectedContainerTypes,
      idx,
    );
    setContainerTypes(filtered);
  }, [selectedContainerTypes, formikQuotationFcl?.values?.cargo_item_specs]);

  return (
    <div>
      <CustomSelect
        name={`cargo_item_specs[${idx}].container_type`}
        options={containerTypes}
        required={true}
        placeholder="Select container type and size"
        errorMessage={
          formikQuotationFcl.errors.cargo_item_specs?.[idx]?.container_type
        }
        showError={Boolean(
          formikQuotationFcl.touched.cargo_item_specs?.[idx]?.container_type,
        )}
        onChange={(value) =>
          formikQuotationFcl.setFieldValue(
            `cargo_item_specs[${idx}].container_type`,
            value,
          )
        }
        onBlur={() =>
          formikQuotationFcl.setFieldTouched(
            `cargo_item_specs[${idx}].container_type`,
            true,
          )
        }
        // onChange={(e: any) => {
        //   if (e) {
        //     updateCargoItemValue(id, "colli_type", e);
        //   }
        // }}
        // value={[itemVal.colli_type]}
        // showError={Boolean(formikQuotationFcl.touched.cargo_item_specs)}
        // errorMessage={formikQuotationFcl.errors.cargo_item_specs}
        label="Container type and size"
        value={
          formikQuotationFcl?.values?.cargo_item_specs?.[idx]?.container_type
        }
      />
    </div>
  );
};

export default ContainerTypeComponent;
