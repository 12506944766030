import React, { useState, useEffect } from "react";
import { findIconByValue } from "src/utils";
import { Steps } from "antd";
import { Icon } from "src/components/atoms/icons";
import { useAppSelector } from "src/store/hook";
import { quotationsSelector } from "src/store/features/quotations/selectors";
import { findSpliceIndexForTranshipment } from "src/helpers";

const VerticalStepsFcl = ({ quotation, quotItem, terms }: any) => {
  const [items, setItems] = useState<any[]>([]); // State for items
  const icon = findIconByValue(quotation.mode_of_transport);
  const oceanIcon = findIconByValue("ocean")?.icon;
  const roadIcon = findIconByValue("road")?.icon;
  const { data } = useAppSelector(quotationsSelector);

  useEffect(() => {
    let initialItems: any[] = [];

    if (terms == "1") {
      // For Door to Port
      initialItems = [
        {
          title: `${data?.source_location || ""} (DOOR)`,
        },
        {
          className: "custom-dot",
          title: roadIcon,
        },
        {
          title: `${data?.source_port}, ${data?.source_country}`,
        },
        {
          className: "custom-dot",
          title: oceanIcon,
        },
        {
          title: `(${data?.destination_port}), ${data?.destination_country}`,
        },
      ];
    } else if (terms == "2") {
      // For Port to Port
      initialItems = [
        {
          title: `(${data?.source_port}), ${data?.source_country} (PORT)`,
        },
        {
          className: "custom-dot",
          title: !icon ? <Icon.McsIcShip /> : icon.icon,
        },
        {
          title: `(${data?.destination_port}), ${data?.destination_country} (PORT)`,
        },
      ];
    } else if (terms == "3") {
      // For Door to Door
      initialItems = [
        {
          title: `${data?.source_location || ""} (DOOR)`,
        },
        {
          className: "custom-dot",
          title: roadIcon,
        },
        {
          title: `${data?.source_port}, ${data?.source_country} (PORT)`,
        },
        {
          className: "custom-dot",
          title: oceanIcon,
        },
        {
          title: `${data?.destination_port}, ${data?.destination_country} (PORT)`,
        },
        {
          className: "custom-dot",
          title: roadIcon,
        },
        {
          title: `${data?.destination_location || ""} (DOOR)`,
        },
      ];
    } else if (terms == "4") {
      // For Port to Door
      initialItems = [
        {
          title: `${data?.source_port}, ${data?.source_country} (PORT)`,
        },
        {
          className: "custom-dot",
          title: oceanIcon,
        },
        {
          title: `${data?.destination_port}, ${data?.destination_country} (PORT)`,
        },
        {
          className: "custom-dot",
          title: roadIcon,
        },
        {
          title: `${data?.destination_location || ""} (DOOR)`,
        },
      ];
    }

    // Handle transshipment
    if (quotation.isTransshipment) {
      const transshipment = JSON.parse(quotation?.Transshipment);
      const itemToPush: any[] = [];

      transshipment.forEach((data: any) => {
        itemToPush.push(
          {
            title: (
              <div className="font-semibold">
                {data?.code}, {data?.name} (PORT)
              </div>
            ),
          },
          {
            className: "custom-dot",
            title: !icon ? <Icon.McsIcShip /> : icon.icon,
          },
        );
      });

      // Insert transshipment items after the 2nd index
      initialItems.splice(
        findSpliceIndexForTranshipment(terms),
        0,
        ...itemToPush,
      );
    }

    setItems(initialItems); // Update state
  }, [quotation]); // Dependencies

  return (
    <div className="py-12">
      <Steps
        progressDot
        direction="vertical"
        size="small"
        className="custom-vertical-steps"
        items={items}
      />
    </div>
  );
};

export default VerticalStepsFcl;
