import React from "react";
import { Icon } from "src/components/atoms/icons";
import ContainerTypeLabel from "../../container-type-label";

export const columns = (handleOpenDeleteModal: any) => {
  return [
    {
      headerName: "File Name",
      field: "name",
      headerClass: "",
      flex: 1,
      minWidth: 410,
      rowDrag: true,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      resizable: true,
    },
    {
      headerName: "Type",
      headerClass: "header-center-align",
      field: "type",
      flex: 0,
      width: 70,
      minWidth: 50,
      maxWidth: 100,
      suppressNavigable: true,
      resizable: true,
      cellRenderer: ({ value }: any) => (
        <div className="flex justify-center py-10">
          <img
            className="h-[20px] w-[20px]"
            src="/assets/images/mcs-googlesheets.jpeg"
            alt="Google Sheets Icon"
          />
        </div>
      ),
    },
    {
      headerName: "Container Type",
      field: "type",
      flex: 0,
      width: 150,
      suppressNavigable: true,
      resizable: true,
      headerClass: "header-center-align",
      cellRenderer: ({ value }: any) => <ContainerTypeLabel value={value} />,
    },
    {
      headerName: "Actions",
      field: "",
      lockPosition: "right",
      suppressNavigable: true,
      width: 200,
      minWidth: 100,
      maxWidth: 100,
      resizable: false,
      cellRenderer: (params: any) => (
        <div className="flex h-full items-center justify-between">
          <button className="cursor-pointer p-8">
            <a
              href={params.data.file_path}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon.McsIcDownload />
            </a>
          </button>

          <button
            className="cursor-pointer p-8"
            onClick={handleOpenDeleteModal}
          >
            <Icon.McsIcDelete />
          </button>
        </div>
      ),
      cellStyle: { paddingLeft: 12, paddingRight: 12 },
      sortable: false,
    },
  ];
};
