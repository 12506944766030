import { useState, useEffect } from "react";
import { useGetLocationsMutation } from "src/services/api-service/locations";
import { ENDPOINTS } from "src/store/endpoints";

const usePickupDestination = ({
  source_country,
  source_port,
  destination_country,
}: any) => {
  const [locations, setLocations] = useState({
    originCountries: [],
    originPorts: [],
    destinationCountries: [],
    destinationPorts: [],
  });

  const [getLocations] = useGetLocationsMutation();

  // Function to fetch locations data with debouncing
  const fetchLocationsDataDebounced = async (
    endpoint: any,
    type: any,
    queryParams: any = {},
  ) => {
    try {
      const res: any = await getLocations({
        endpoint,
        ...queryParams,
      });
      setLocations((prev) => ({
        ...prev,
        [type]: res?.data,
      }));
    } catch (err) {
      setLocations((prev) => ({
        ...prev,
        [type]: [],
      }));
      console.log("GETTING ERROR IN FETCHING LOCATIONS......");
    }
  };

  // Fetch all origin countries
  useEffect(() => {
    fetchLocationsDataDebounced(
      ENDPOINTS.ADMIN.ORIGIN_COUNTRIES,
      "originCountries",
    );
  }, []);

  // Fetch origin ports based on origin country
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      source_country &&
        fetchLocationsDataDebounced(
          ENDPOINTS.ADMIN.ORIGIN_PORTS_BY_COUNTRY,
          "originPorts",
          {
            country: source_country,
          },
        );
    }, 300);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [source_country]);

  // Fetch destination country based on source port
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      source_port &&
        fetchLocationsDataDebounced(
          ENDPOINTS.ADMIN.DESTINATION_COUNTRIES_BY_PORTS,
          "destinationCountries",
          {
            port: source_port,
          },
        );
    }, 300);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [source_port]);

  // Fetch destination ports based on origin country
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      destination_country &&
        source_country &&
        fetchLocationsDataDebounced(
          ENDPOINTS.ADMIN.DESTINATION_PORTS,
          "destinationPorts",
          {
            port: source_port,
            country: destination_country,
          },
        );
    }, 300);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [destination_country, source_country]);

  // Reset locations data
  const resetLocationsData = (type: any) => {
    setLocations((prev: any) => ({
      ...prev,
      [type]: [],
    }));
  };

  return {
    ...locations,
    resetLocationsData,
  };
};

export default usePickupDestination;
