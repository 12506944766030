import React, { useEffect, useState } from "react";
import { Checkbox, CheckboxProps, Collapse, Select } from "antd";
import { Icon } from "src/components/atoms/icons";
import CustomButton from "src/components/atoms/button";
import CustomModal from "src/components/molecules/custom-modal";

import QuotationSelected from "src/pages/quotation-results/elements/quotation-selected";
import QuotationRoutesFcl from "../quotation-routes-fcl";
import AllInRateComponent from "src/components/atoms/all-in-rate/all-in-rate.component";
import ChargesTableFcl from "../charges-table-fcl";
import useValidCharges from "src/hooks/useValidCharges";
import { useAppDispatch, useAppSelector } from "src/store/hook";
import { quotationsSelector } from "src/store/features/quotations/selectors";
import {
  setFilteredQuotations,
  setSelectedQuotations,
  updateQuotationCharges,
} from "src/store/features/quotations";
import CarrierFclComponent from "../carrier-fcl/carrier-fcl.component";
import { alertsSelector } from "src/store/features/alerts/selectors";
import CardLoader from "../card-loader";
import { useNavigate } from "react-router-dom";
import useQueryParams from "src/hooks/useQueryParams";
import {
  addEditableFields,
  calculateFinalTotalPrice,
  getTransShipmentCount,
  parseCurrency,
} from "src/helpers";
import { fclLoadingSelector } from "src/store/features/quotations-fcl/selectors";
import { useUpdatePriceFclMutation } from "src/services/api-service/quotations";
import { showToast } from "src/utils";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";

const values = [1, 2, 3, 4, 5];
const chargesdata = {
  columns: [
    { container_type: "40HC", quantity: "1" },
    { container_type: "40HC", quantity: "1" },
    { container_type: "40HC", quantity: "1" },
    { container_type: "40HC", quantity: "1" },
    { container_type: "40HC", quantity: "1" },
  ],
  charges: [
    {
      id: 1,
      category: "Freight Charges",
      total: 900,
      details: [
        {
          id: 1,
          name: "Included charges: Bunker Recovery Adjustment. Factor/Bunker surcharge NOS/Ocean Carrier-Intl Ship & port Facility Security/Terminal handl ch origin",
          values: values,
          isEditable: true,
        },
      ],
      isEditable: true,
    },
    {
      id: 2,
      category: "Origin Charges",
      total: 800,
      details: [
        {
          id: 1,
          name: "Pre Carriage Additional - Congestion",
          values: values,
          isEditable: true,
        },
      ],
      isEditable: true,
    },
    {
      id: 3,
      category: "Destination Charges",
      total: 400,
      details: [
        {
          id: 1,
          name: "Container cleaning at destination",
          values: values,
          isEditable: true,
        },
        {
          id: 2,
          name: "Contingency Charge",
          values: values,
          isEditable: true,
        },
        {
          id: 3,
          name: "EU ETS Surcharge",
          values: values,
          isEditable: true,
        },
      ],
      isEditable: true,
    },
  ],
};

const QuotationResultsCardFcl = ({ isAdmin }: { isAdmin: boolean }) => {
  const [updatePriceFcl] = useUpdatePriceFclMutation();

  const [chargesData, setChargesData] = useState(chargesdata);
  const [isEditMode, setIsEditMode] = useState(false);

  const isValidCharges = useValidCharges(chargesData);

  const fclLoading = useAppSelector(fclLoadingSelector);

  const [activeKey, setActiveKey] = useState("");
  const { getQueryParam } = useQueryParams();
  const mainQuotationId = getQueryParam("id");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { filteredQuotations, selectedQuotations, data } =
    useAppSelector(quotationsSelector);

  const onCheckboxChange: CheckboxProps["onChange"] = (id) => {
    const result = [...selectedQuotations];
    const index = result.indexOf(id);
    if (index > -1) {
      result.splice(index, 1);
    } else {
      result.push(id);
    }
    dispatch(setSelectedQuotations(result));
  };

  const requestQuote = (id: number, quotationId: string) => {
    navigate(`/confirm-quotation/${id}?quoteId=${quotationId}`);
  };

  const handleUpdateChargesFcl = (initialCharges: any) => {
    // return;
    updatePriceFcl({
      quotation_id: activeKey,
      chargesData,
      customer_id: data?.customer_id,
    })
      .unwrap()
      .then((res) => {
        dispatch(
          updateQuotationCharges({
            subQuotationId: activeKey,
            chargesData: res?.data,
          }),
        );

        showToast(TOASTR_TYPES.SUCCESS, res?.message);
        setIsEditMode(false);
      })
      .catch((err) => {
        showToast(TOASTR_TYPES.ERROR, err?.data?.message);
        // setChargesData(initialCharges)
        console.log(err);
      });
  };

  useEffect(() => {
    return () => {
      dispatch(setSelectedQuotations([]));
    };
  }, []);

  const handleSortChange = (value: string) => {
    const results: any[] = [...filteredQuotations];

    if (value == "transit_time") {
      results.sort((a: any, b: any) => {
        return a[value] - b[value];
      });
    } else if (value.includes("date")) {
      results.sort((a: any, b: any) => {
        const dateA: any = new Date(a[value]);
        const dateB: any = new Date(b[value]);
        return dateA - dateB;
      });
    } else {
      results.sort(
        (a: any, b: any) => parseCurrency(a?.price) - parseCurrency(b?.price),
      );
    }

    dispatch(setFilteredQuotations(results));
  };

  return (
    <>
      <div className="flex justify-between py-10 pt-20">
        <label className="m-0 w-2/5 py-6 pr-5 text-slate-500">
          Showing {filteredQuotations?.length} results
        </label>
        <div className="flex">
          <div className="mr-5 w-full py-6 text-end ">Sort by:</div>
          <div className="w-[200px]">
            <Select
              // name=""
              onChange={handleSortChange}
              placeholder="Price"
              className="w-full"
              options={[
                { label: "Price", value: "price" },
                // { label: "Fastest Route", value: "fastest" },
                { label: "Transit time", value: "transit_time" },
                { label: "Cargo Ready Date", value: "date" },
              ]}
              popupClassName="!w-[165px]"
              // required={false}
            />
          </div>
        </div>
      </div>
      <hr />

      <div className="py-10"></div>
      {fclLoading ? (
        <>
          <CardLoader />
          <CardLoader />
          <CardLoader />
        </>
      ) : (
        <div className="overflow-x-auto">
          <div className="mb-80 min-w-[1140px]">
            {filteredQuotations?.map((quotation: any) => {
              const isSelected = selectedQuotations?.includes(quotation.id);
              return (
                <div
                  key={quotation?.id}
                  className={`relative mb-30 flex flex-col rounded-[10px] border-primary-100  ${isSelected ? "bg-primary-100" : "bg-primary-50"} px-24 pt-8 shadow-card-shadow`}
                >
                  <div className="absolute left-[12px] top-[6px]">
                    <Checkbox
                      checked={selectedQuotations?.includes(quotation.id)}
                      onChange={() => onCheckboxChange(quotation.id)}
                    ></Checkbox>
                  </div>
                  <div className="flex flex-col border-b border-[#AFB6D1] ">
                    <div className=" grid grid-cols-12 border-b  border-[#AFB6D1] pb-8">
                      <div className="col-span-1 ml-12 text-14 font-normal leading-20 text-primary-600">
                        Carrier
                      </div>
                      <div
                        className={`${quotation?.categorised_charges?.columns?.length == 1 ? "col-span-8" : "col-span-6 "} flex justify-center text-14 font-normal leading-20 text-primary-600`}
                      >
                        Route
                      </div>
                      <div
                        className={`${quotation?.categorised_charges?.columns?.length == 1 ? "col-span-1" : "col-span-3"}  flex max-w-[320px] justify-center text-14 font-normal leading-20 text-primary-600`}
                      >
                        All in rate
                      </div>
                      <div className="flex-2 col-span-2 flex justify-center pl-24 text-14 font-normal leading-20 text-primary-600">
                        Total Price
                      </div>
                    </div>

                    <div className="mb-20 flex w-full gap-x-4 overflow-x-auto pt-12">
                      <CarrierFclComponent
                        carrierCode={quotation?.carrier_code}
                      />
                      <div className="w-full">
                        <QuotationRoutesFcl
                          date={""}
                          startDate={quotation?.date}
                          endDate={quotation?.delivery_date}
                          mode="Air"
                          isStop={false}
                          transit="Direct"
                          transitTime={quotation?.transit_time}
                          secondMode="Test"
                          transhipMentCount={
                            quotation?.service_type == "fcl"
                              ? getTransShipmentCount(quotation)
                              : 1
                          }
                          data={data}
                        />
                      </div>
                      <div className="relative flex h-auto max-w-[320px] items-center  rounded-lg">
                        <AllInRateComponent
                          data={quotation?.categorised_charges?.columns}
                        />
                      </div>
                      <div className="flex min-w-[110px] flex-col justify-center gap-2">
                        <p>
                          <span className="text-17 font-normal leading-32 text-gray-600">
                            EUR{" "}
                            {/* {quotation?.categorised_charges?.total_price} */}
                            {calculateFinalTotalPrice({
                              charges: quotation?.categorised_charges?.charges,
                              columns: quotation?.categorised_charges?.columns,
                            })}
                          </span>
                        </p>
                        {!isAdmin && (
                          <CustomButton
                            variant="primary"
                            className="w-full rounded-lg  !bg-orange-600 text-14 font-semibold hover:!bg-orange-500"
                            disabled={false}
                            title="Coming soon"
                            onClick={() =>
                              requestQuote(quotation.id, mainQuotationId)
                            }
                          >
                            Book Now
                          </CustomButton>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <Collapse
                      destroyInactivePanel
                      activeKey={activeKey}
                      items={[
                        {
                          key: quotation?.id,
                          label: (
                            <div className="flex items-center justify-center">
                              <div className="expand-text text-12  font-semibold leading-18 text-primary-400">
                                Expand for price breakdown and timeline
                              </div>
                              <div className="shrink-back-text text-12 font-semibold leading-18 text-primary-400">
                                Shrink Back
                              </div>
                              <div className="icon">
                                <Icon.McsIcCollapseDown />
                              </div>
                            </div>
                          ),
                          children: (
                            <div
                              className={`mb-10 flex flex-col px-10 ${isSelected ? "bg-primary-100" : ""}`}
                            >
                              <ChargesTableFcl
                                initialChages={quotation?.categorised_charges}
                                chargesData={addEditableFields(chargesData)}
                                setChargesData={setChargesData}
                                isValidCharges={isValidCharges}
                                showEditButton={isAdmin}
                                quotation={quotation}
                                stickyColClassName={
                                  isSelected
                                    ? "bg-primary-100"
                                    : "bg-primary-50"
                                }
                                handleUpdateChargesFcl={handleUpdateChargesFcl}
                                isEditMode={isEditMode}
                                setIsEditMode={setIsEditMode}
                              />
                            </div>
                          ),
                        },
                      ]}
                      className="custom-collapse"
                      onChange={(key) => {
                        if (key) {
                          setChargesData(quotation?.categorised_charges);
                        }
                        setActiveKey((prev) => {
                          return key[1];
                        });
                        setIsEditMode(false);
                      }}
                    />
                  </div>
                  <CustomModal
                    title="Port Remarks"
                    isOpen={false}
                    toggle={() => {
                      console.log("hi");
                    }}
                    maskClosable={true}
                    icon={<Icon.McsIcChevronBack />}
                    width={955}
                    innerClass="custom-remarks-modal"
                  >
                    <div className="flex flex-col gap-2">
                      <p className="text-12 font-normal leading-18 text-primary-400">
                        BRSSZ
                      </p>
                      <ul className="modal-list">
                        <li>Fumigation required for all wooden packages.</li>
                        <li>MANIFEST CLOSING 1 DAY BEFORE SAILING</li>
                        <li>Original B/L needed.</li>
                        <li>
                          B/L must mention ncm code, please advise before vessel
                          departing.
                        </li>
                        <li>
                          Customs Clearance at Port of Entry by Consignee.
                        </li>
                        <li>CNPJ number of consignee is mandatory.</li>
                        <li>Oceanfreight to be show on HBL.</li>
                        <li>
                          DG cargo : For all hazardous cargo MSDS required prior
                          shipping and subject approval carriers/agent approval.
                          (Imo 2 is not accepted)
                        </li>
                        <li>
                          Manifest Correction Eur 250,* + all additional charges
                          caused by correction
                        </li>
                        <li>
                          Personal effects / Household goods : to Brasil not
                          acceptable
                        </li>
                        <li>
                          Marks and Numbers: All goods must have marks and
                          sequential identification number on the total of
                          packages contemplated by document shipments.
                        </li>
                      </ul>
                    </div>
                  </CustomModal>
                </div>
              );
            })}
          </div>
        </div>
      )}

      <QuotationSelected />
    </>
  );
};

export default QuotationResultsCardFcl;
