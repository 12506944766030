import React from "react";
import { QuotationsContextProvider } from "src/context/quotations-context";
import { QuotationsContextProviderFcl } from "src/context/quotations-context-fcl";

const ContextProvider = ({ children }: any) => {
  return (
    <QuotationsContextProvider>
      <QuotationsContextProviderFcl>{children}</QuotationsContextProviderFcl>
    </QuotationsContextProvider>
  );
};

export default ContextProvider;
