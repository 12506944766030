import React from "react";
import { Link } from "react-router-dom";
import { formatQuotationReference } from "src/helpers";
import useFormattedDate from "src/hooks/useFormattedDate";
import { statusColor } from "src/utils";

export const getColumns = () => [
  {
    headerName: "Reference No.",
    field: "reference_no",
    width: 70,
    headerClass: "", // Add this line
    flex: 1,
    suppressMovable: true,
    editable: false,
    suppressNavigable: true,
    minWidth: 190,
    cellRenderer: ({ value, data }: any) => {
      console.log("data", data);

      return (
        <div className="text-wrap overflow-auto">
          <span>
            {data?.main_quotation
              ? formatQuotationReference(data?.main_quotation)
              : data?.reference_no}
          </span>
        </div>
      );
    },
  },
  {
    headerName: "Creation Date",
    field: "created_at",
    flex: 1,
    width: 120,
    suppressMovable: true,
    editable: false,
    suppressNavigable: true,
    minWidth: 130,
    cellRenderer: ({ value }: any) => (
      <span className="leading-7">
        {useFormattedDate(value).split(",")[0] +
          ", " +
          useFormattedDate(value).split(",")[1]}
      </span>
    ),
  },
  {
    headerName: "Origin",
    field: "source_location",
    flex: 1,
    suppressMovable: true,
    editable: false,
    suppressNavigable: true,
    minWidth: 150,
    valueGetter: (params: any) =>
      params.data.source_location ?? params.data.main_quotation?.source_country,
  },
  {
    headerName: "Destination",
    field: "destination_location",
    flex: 1,
    suppressMovable: true,
    editable: false,
    suppressNavigable: true,
    minWidth: 150,
    valueGetter: (params: any) =>
      params.data.destination_location ??
      params.data.main_quotation?.destination_country,
  },
  {
    headerName: "Shipment Mode",
    field: "mode_of_transport",
    flex: 1,
    suppressMovable: true,
    editable: false,
    suppressNavigable: true,
    minWidth: 100,
    valueGetter: (params: any) => params.data.mode_of_transport.toUpperCase(),
  },
  {
    headerName: "Service Type",
    field: "service_type",
    flex: 1,
    suppressMovable: true,
    editable: false,
    suppressNavigable: true,
    minWidth: 100,
    valueGetter: (params: any) => params.data.service_type.toUpperCase(),
  },
  {
    headerName: "Status",
    field: "status",
    flex: 1,
    suppressMovable: true,
    editable: false,
    suppressNavigable: true,
    cellStyle: {
      paddingLeft: 5,
      paddingRight: 5,
      display: "flex",
      alignItems: "center",
    },
    minWidth: 130,
    cellRenderer: (params: any) => {
      return (
        <div
          className={`w-fit rounded-2xl px-8 py-2 text-12 font-medium capitalize leading-18 ${statusColor.get(params.data.status)}`}
        >
          {params.data.status}
        </div>
      );
    },
  },
  {
    headerName: "Download pdf",
    field: "download_pdf",
    flex: 1,
    width: 70,
    suppressMovable: true,
    editable: false,
    suppressNavigable: true,
    minWidth: 190,
    cellRenderer: (value: any) => (
      <div className="!mt-10 block leading-17">
        <Link
          className="min-w-[210px] text-14 font-medium leading-20 text-blue-1 hover:text-blue-1"
          to={""}
        >
          <p className="truncate">
            {" "}
            {formatQuotationReference(value?.data?.main_quotation)}_booking.pdf
          </p>
        </Link>
        {/* <a
          href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
          target="_blank"
          rel="noreferrer"
          download
        >
          something.pdf
        </a> */}
      </div>
    ),
  },
];
