import React, { useEffect, useLayoutEffect, useState } from "react";
import { Icon } from "src/components/atoms/icons";
import CustomButton from "src/components/atoms/button";
import CustomDatePicker from "src/components/atoms/date-picker";
import LocationPicker from "src/components/atoms/location-picker";
import { useQuotationsContext } from "src/context/quotations-context";
import { useGetCountriesListMutation } from "src/services/api-service/countries";
import { Country } from "src/pages/quotations/elements/generate-quotation/types";
import { useLocation, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store/hook";
import { quotationsSelector } from "src/store/features/quotations/selectors";
import useCheckDisabled from "src/hooks/useCheckDisabled";
import { formatDateResult } from "src/utils";
import usePickupDestination from "src/hooks/usePickupDestination";
import { setSelectedQuotations } from "src/store/features/quotations";
import PortSelecter from "src/components/atoms/port-selecter";
import CountrySelecter from "src/components/atoms/country-selecter";
import { useGetPostcodesListMutation } from "src/services/api-service/postcode";

const QuotationResultsFiltersComponent = ({ getDetails, isCustomer }: any) => {
  const { data } = useAppSelector(quotationsSelector);

  const [startValue, setStartValue] = useState();
  const { formikQuotation } = useQuotationsContext();
  const [countries, setCountries] = useState<Country[]>([]);

  const [getCountriesList, { data: countriesData }] =
    useGetCountriesListMutation();

  // Fetch postcodes for source location
  const [getPostcodesList, { data: PostcodesData }] =
    useGetPostcodesListMutation();

  useEffect(() => {
    const fetchPostcodes = async () => {
      try {
        await getPostcodesList({});
      } catch (error) {
        console.error("Error fetching source postcodes:", error);
      }
    };
    fetchPostcodes();
  }, [getPostcodesList]);

  const [url] = useSearchParams();
  const id: string = url?.get("id") ?? "";
  const { isDisabled, handleInputChange } = useCheckDisabled();
  const [sessionData, setSessionData] = useState(null);
  const [dimensions, setDimensions] = useState({
    volume: 0,
    weight: 0,
    quantity: 0,
  });
  const locaton = useLocation();
  const dispatch = useAppDispatch();

  const {
    originCountries,
    destinationCountries,
    originPorts,
    destinationPorts,
    resetLocationsData,
  } = usePickupDestination(formikQuotation?.values);

  const calculateTotalDimensions = (data: any) => {
    let totalVolume = 0;
    let totalWeight = 0;
    let totalQuantity = 0;

    const quotedata: any = JSON.parse(JSON.stringify(data || {}));
    const isTotalCargo: boolean = quotedata?.cargo_total_dimensions?.length;
    const specs: any[] = quotedata?.cargo_item_specs?.length
      ? quotedata?.cargo_item_specs
      : quotedata?.cargo_total_dimensions;

    !isTotalCargo &&
      specs?.forEach((item: any) => {
        totalVolume += item.height * item.length * item.width * item.quantity;
        totalWeight += parseFloat(item.weight) * item.quantity;
        totalQuantity += item.quantity;
      });
    isTotalCargo &&
      ((totalVolume = parseFloat(specs[0].volume)),
      (totalWeight = parseFloat(specs[0].weight)));

    // Assuming `setDimensions` is a state updater function
    console.log(totalVolume);
    setDimensions(() => ({
      volume: totalVolume,
      weight: totalWeight,
      quantity: totalQuantity,
    }));
  };

  const dataFilter = [
    {
      title: "Service Type",
      value: data?.service_type?.toUpperCase() || "-",
    },
    {
      title: "Total Quantity",
      value: data?.cargo_item_specs?.length
        ? dimensions?.quantity
        : "Total Cargo",
    },
    {
      title:
        "Weight" +
        (data?.cargo_item_specs && Boolean(+data?.cargo_item_specs[0]?.cm_kg)
          ? " (Kg)"
          : " (lb)"),
      value: (dimensions?.weight / 1).toFixed(2) || "-",
      // (data?.cargo_item_specs && data?.cargo_item_specs[0]?.is_cm ? " Kg" : " lb"),
    },
    {
      title:
        "Volume" +
        (data?.cargo_item_specs && Boolean(+data?.cargo_item_specs[0]?.cm_kg)
          ? " (M³)"
          : " (ft³)"),
      value: formikQuotation?.values?.total_cargo_dimensions
        ? (dimensions?.volume / 1)?.toFixed(3)
        : (
            dimensions?.volume /
            (data?.cargo_item_specs?.length && data?.cargo_item_specs[0]?.cm_kg
              ? 1000000
              : 1728)
          ).toFixed(3) || "-",
      // (data?.cargo_item_specs && data?.cargo_item_specs[0]?.is_cm ? " M³" : " ft³"),
    },
  ];

  useEffect(() => {
    calculateTotalDimensions(data);
    setSessionData(data);
    compareData();
  }, [id, data]);

  const compareData = () => {
    const keys: string[] = [
      "source_country",
      "source_port",
      "destination_country",
      "destination_port",
      "date",
    ];
    const storedData = {
      source_country: sessionData?.["source_country"],
      source_port: sessionData?.["source_port"],
      source_location: sessionData?.["source_location"],
      destination_country: sessionData?.["destination_country"],
      destination_port: sessionData?.["destination_port"],
      destination_location: sessionData?.["destination_location"],
      date: sessionData?.["date"],
    };
    const formData = {
      source_country: formikQuotation?.values?.["source_country"],
      source_port: formikQuotation?.values?.["source_port"],
      source_location: formikQuotation?.values?.["source_location"],
      destination_country: formikQuotation?.values?.["destination_country"],
      destination_port: formikQuotation?.values?.["destination_port"],
      destination_location: formikQuotation?.values?.["destination_location"],
      date: formikQuotation?.values?.["date"],
    };
    const delayDebounceFn = setTimeout(() => {
      handleInputChange(formData, keys, storedData, id);
    }, 200);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  };

  useLayoutEffect(() => {
    compareData();
  }, [formikQuotation.values, isDisabled]);

  const generateNewQuotation = () => {
    formikQuotation.submitForm();
    if (!locaton.pathname.includes("/admin")) {
      dispatch(setSelectedQuotations([]));
    }
  };

  useEffect(() => {
    const syncData: any = JSON.parse(JSON.stringify(data || {}));
    formikQuotation.setValues((values: any) => ({
      ...values,
      customer_id: JSON.stringify(syncData?.customer_id),
      reference_no: syncData?.reference_no,
      date: syncData?.date,
      source_country: syncData?.source_country,
      source_location: syncData?.source_location,
      source_port: syncData?.source_port,
      destination_country: syncData?.destination_country,
      destination_location: syncData?.destination_location,
      destination_port: syncData?.destination_port,
      service_type: syncData?.service_type,
      quotation_type: isCustomer ? "external" : "internal",
      total_cargo_dimensions: !!syncData?.cargo_total_dimensions?.length,
      cargo_item_specs: syncData?.cargo_total_dimensions?.length
        ? syncData?.cargo_total_dimensions
        : syncData?.cargo_item_specs,
    }));
    setStartValue(syncData?.date);
  }, [data]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        await getCountriesList({});
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, [getCountriesList]);

  useEffect(() => {
    if (countriesData && Array.isArray(countriesData)) {
      setCountries(countriesData as Country[]);
    }
  }, [countriesData]);

  const swapLocations = () => {
    // Swap locations
    const originalData = JSON.parse(JSON.stringify(formikQuotation?.values));
    formikQuotation.setValues({
      ...formikQuotation?.values,
      source_country: originalData?.destination_country,
      source_port: originalData?.destination_port,
      source_location: originalData?.destination_location,
      destination_country: originalData?.source_country,
      destination_port: originalData?.source_port,
      destination_location: originalData?.source_location,
    });
  };

  return (
    <div className="mt-10 rounded-[10px] border-border bg-primary-50 px-24 py-20">
      <form className="flex gap-[20px]">
        <div style={{ width: "100%" }} className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <div className="flex-1">
              <CountrySelecter
                placeholder="Enter a Country"
                name="source_country"
                label="Origin"
                value={formikQuotation?.values?.source_country}
                onChange={(value: any) => {
                  formikQuotation?.setFieldValue("source_country", "");
                }}
                onSelect={(value: string) => {
                  resetLocationsData("originPorts");
                  resetLocationsData("destinationCountries");
                  resetLocationsData("destinationPorts");

                  formikQuotation?.setValues({
                    ...formikQuotation?.values,
                    source_port: "",
                    destination_country: "",
                    destination_port: "",
                  });
                  formikQuotation?.setFieldValue("source_country", value);
                }}
                countries={originCountries} // Pass the fetched countries data here
                required={true}
                allowCustomValue={true}
              />
            </div>
            <div className="flex-1 self-end">
              <PortSelecter
                placeholder="Select a Port"
                name="source_port"
                value={formikQuotation?.values?.source_port}
                onChange={(value: any) => {
                  formikQuotation?.setFieldValue("source_port", "");
                }}
                onSelect={(value: string) => {
                  resetLocationsData("destinationCountries");
                  resetLocationsData("destinationPorts");
                  formikQuotation?.setValues({
                    ...formikQuotation?.values,
                    destination_country: "",
                    destination_port: "",
                  });
                  formikQuotation?.setFieldValue("source_port", value);
                }}
                ports={originPorts} // Pass the fetched ports data here
                required={true}
                allowCustomValue={true}
              />
            </div>
          </div>
          <LocationPicker
            placeholder="Enter Shipment Pickup (optional)"
            name="source_location"
            value={formikQuotation?.values?.source_location}
            onChange={(value: any) => {
              formikQuotation?.setFieldValue("source_location", value);
            }}
            onSelect={(value: string) => {
              formikQuotation?.setFieldValue("source_location", value);
            }}
            required={false}
            postcodes={PostcodesData || []} // Pass postcodes data here
            countryCode={formikQuotation?.values?.source_port?.substring(0, 2)}
            // disabled={!Boolean(formikQuotation.values.source_port)}
          />
        </div>
        <div className="mt-26 flex items-center">
          <button type="button" onClick={swapLocations} className="">
            <Icon.McsIcFiltersExchange />
          </button>
        </div>
        <div style={{ width: "100%" }} className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <div className="flex-1 self-end">
              <CountrySelecter
                placeholder="Enter a Country"
                name="destination_country"
                label="Destination"
                value={formikQuotation?.values?.destination_country}
                onChange={(value: any) => {
                  formikQuotation?.setFieldValue("destination_country", "");
                }}
                onSelect={(value: string) => {
                  resetLocationsData("destinationPorts");
                  formikQuotation?.setValues({
                    ...formikQuotation?.values,
                    destination_port: "",
                  });
                  formikQuotation?.setFieldValue("destination_country", value);
                }}
                countries={destinationCountries} // Pass the fetched countries data here
                required={true}
                allowCustomValue={true}
              />
            </div>
            <div className="flex-1 self-end">
              <PortSelecter
                placeholder="Select a Port"
                name="destination_port"
                value={formikQuotation?.values?.destination_port}
                onChange={(value: any) => {
                  formikQuotation?.setFieldValue("destination_port", "");
                }}
                onSelect={(value: string) => {
                  formikQuotation?.setFieldValue("destination_port", value);
                }}
                ports={destinationPorts} // Pass the fetched ports data here
                required={true}
                allowCustomValue={true}
              />
            </div>
          </div>
          <LocationPicker
            placeholder="Enter Destination Location (optional)"
            name="destination_location"
            value={formikQuotation?.values?.destination_location}
            onChange={(value: any) => {
              formikQuotation?.setFieldValue("destination_location", value);
            }}
            onSelect={(value: string) => {
              formikQuotation?.setFieldValue("destination_location", value);
            }}
            required={false}
            postcodes={PostcodesData || []} // Pass postcodes data here
            countryCode={formikQuotation?.values?.source_port?.substring(0, 2)}
            disabled={!Boolean(formikQuotation.values.source_port)}
          />
        </div>

        <div style={{ width: "50%" }} className="flex flex-col gap-2">
          <CustomDatePicker
            value={startValue}
            onChange={(e: any) => {
              formikQuotation?.setFieldValue(
                "date",
                formatDateResult(new Date(e)),
              );
              console.log(new Date(e));
              setStartValue(e);
            }}
            name="date"
            label="Cargo Ready Date"
            containerClassName="date-of-birth-date-picker"
            className="w-full"
            // disabled={Boolean(id)}
          />
          <div className="w-1/2">
            <CustomButton
              variant="primary-icon-btn"
              onClick={() => {
                generateNewQuotation();
              }}
              disabled={isDisabled}
            >
              <div className="flex items-center gap-2">
                <Icon.McsIcSearchWhite />
                <span className="text-20">Search</span>
              </div>
            </CustomButton>
          </div>
        </div>
      </form>
      <div className="mt-12 flex flex-wrap gap-4 rounded-[10px] bg-primary-50 py-12">
        {dataFilter?.map((d, i) => {
          return (
            <div
              className={
                !(formikQuotation?.values?.total_cargo_dimensions && i === 1)
                  ? "flex min-w-[106px] flex-col gap-1"
                  : "hidden"
              }
              key={i}
            >
              <p className="text-14 font-medium leading-20 text-gray-400">
                {d.title}
              </p>
              <p>{d.value}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default QuotationResultsFiltersComponent;
