import React from "react";

const CARRIERS: any = {
  HPL: {
    code: "HPL",
    image: "hapag.svg",
  },
  MSK: {
    code: "MSK",
    image: "maersk.svg",
  },
};

const CarrierFclComponent = ({ carrierCode }: { carrierCode: string }) => {
  return (
    <div className="flex min-w-[85px] max-w-[110px] flex-1 flex-col items-center justify-center text-center">
      {CARRIERS[carrierCode]?.image && (
        <img
          className="mx-auto w-[74px]"
          src={`../../../assets/images/carriers/${CARRIERS[carrierCode]?.image}`}
          alt={carrierCode}
          title="marinair-logo"
        />
      )}
      <p className="text-16 font-semibold leading-24 text-gray-700">
        {carrierCode}
      </p>
    </div>
  );
};

export default CarrierFclComponent;
