import React from "react";

const ContainerTypeLabelComponent = ({ value }: { value: any }) => {
  if (value == "fcl") {
    return (
      <div className="flex h-full items-center justify-center">
        <span className="rounded-[16px] bg-indigo-700 px-8 py-2 text-12 font-medium leading-18 text-white">
          FCL
        </span>
      </div>
    );
  } else {
    return (
      <div className="flex h-full items-center justify-center">
        <span className="rounded-[16px] bg-indigo-50 px-8 py-2 text-12 font-medium leading-18 text-indigo-700">
          LCL
        </span>
      </div>
    );
  }
};

export default ContainerTypeLabelComponent;
