import React, { useEffect, useState } from "react";
import ContentWrapper from "src/components/layouts/content-wrapper";
import { useGetQuotationDetailsMutation } from "src/services/api-service/quotations";
import { useSearchParams } from "react-router-dom";
import QuotationsResultFilters from "src/pages/quotation-results/elements/quotation-results-filters";
import CustomerPanelLayout from "src/components/layouts/customer-panel-layout";
import BackButton from "src/components/atoms/back-button";
import QuotationResultsCard from "src/pages/quotation-results-customer/elements/quotation-results-card";
import QuotationResultFiltersFcl from "./elements/quotations-result-filters-fcl";
import QuotationResultCardFcl from "./elements/quotations-result-card-fcl";
import { useAppSelector } from "src/store/hook";
import { quotationsSelector } from "src/store/features/quotations/selectors";
import { useQuotationsContext } from "src/context/quotations-context";
import { useDispatch } from "react-redux";
import { setLoadingFcl } from "src/store/features/quotations-fcl";

const CustomerQuotationsResultComponent = () => {
  const [url] = useSearchParams();
  const id: string = url?.get("id") || "";

  const { formikQuotation } = useQuotationsContext();
  const [getDetailsMutation] = useGetQuotationDetailsMutation<any>();
  const [reload] = useState(false);

  const quotation = useAppSelector(quotationsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    getDetails();
  }, [id]);

  const getDetails = () => {
    dispatch(setLoadingFcl(true));
    id &&
      getDetailsMutation({ id })
        .unwrap()
        .finally(() => {
          dispatch(setLoadingFcl(false));
        });
  };

  useEffect(() => {
    if (reload) getDetails();
  }, [reload]);

  const href = `/quotations?tab=Generate%20Quotations&id=${id}`;

  return (
    <CustomerPanelLayout
      isSidebarOpen={true}
      isResultsPage={true}
      breadcrumbsData={[
        { title: "Generate Quotations", href: href },
        { title: "Results" },
      ]}
    >
      <ContentWrapper>
        {(quotation?.data?.service_type == "lcl" ||
          formikQuotation?.values?.service_type == "lcl") && (
          <>
            <BackButton text="Back to edit search" href={href} />
            <QuotationsResultFilters
              getDetails={getDetails}
              isCustomer={true}
            />
            <QuotationResultsCard />
          </>
        )}
        {(quotation?.data?.service_type == "fcl" ||
          formikQuotation?.values?.service_type == "fcl") && (
          <>
            <BackButton text="Back to edit search" href={href} />
            <QuotationResultFiltersFcl getDetails={getDetails} />
            <QuotationResultCardFcl isAdmin={false} />
          </>
        )}
      </ContentWrapper>
    </CustomerPanelLayout>
  );
};

export default CustomerQuotationsResultComponent;
