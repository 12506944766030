import Label from "../label";
import React, { useState } from "react";
import ErrorLabel from "../error-label";
import { ITextInput } from "./text-input.types";
import { Input } from "antd";
import "./text-input.css";
const TextInput = ({
  id,
  name,
  label,
  type,
  size = "large",
  classes,
  placeholder,
  disabled,
  readonly,
  value,
  required = false,
  errorMessage = "",
  showError,
  onChange,
  onFocus,
  onBlur,
  autoFocus,
  allowNumbersOnly = false,
  prefix,
  suffix,
  allowDecimals = false,
  maxDigits,
  onKeyDown,
}: ITextInput) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;

    if (allowNumbersOnly) {
      // Remove non-numeric characters
      inputValue = inputValue.replace(/\D/g, "");

      // Remove leading zeros
      inputValue = inputValue.replace(/^0+/, "");

      // Enforce max digits
      if (maxDigits !== undefined && inputValue.length > maxDigits) {
        inputValue = inputValue.slice(0, maxDigits);
      }
    } else if (allowDecimals) {
      // Remove all non-numeric characters except the decimal point
      inputValue = inputValue.replace(/[^\d.]/g, "");

      // Remove leading zeros
      if (!inputValue.startsWith("0.")) {
        inputValue = inputValue.replace(/^0+/, "");
      }

      // Ensure that there is at most one decimal point
      const parts = inputValue.split(".");
      if (parts.length > 2) {
        inputValue = parts[0] + "." + parts.slice(1).join("");
      }
    }

    e.target.value = inputValue;
    onChange && onChange(e);
  };

  const commonInputProps: any = {
    name,
    value,
    type,
    onBlur,
    onChange: handleInputChange,
    onKeyDown,
    placeholder,
    prefix,
    suffix,
    size,
    disabled,
    classNames: {
      input:
        "placeholder:text-16 text-16 placeholder:text-gray-500-base-light text-gray-800 font-normal leading-24 border border-gray-300 rounded-lg shadow-input-field-shadow " +
        classes?.inputClassName,
    },
    status: showError && errorMessage ? "error" : "",
  };

  const passwordInputProps: any = {
    visibilityToggle: {
      visible: passwordVisible,
      onVisibleChange: setPasswordVisible,
    },

    readOnly: readonly,
  };

  return (
    <div className={classes?.containerClassName}>
      {label && <Label id={id || name} name={label} required={required} />}
      {type === "password" ? (
        <Input.Password {...commonInputProps} {...passwordInputProps} />
      ) : (
        <Input
          {...commonInputProps}
          onFocus={onFocus}
          autoFocus={autoFocus}
          onWheel={(e: any) => e.preventDefault()}
        />
      )}
      {showError && (
        <ErrorLabel
          message={errorMessage}
          className={classes?.errorLabelClass}
        />
      )}
    </div>
  );
};

export default TextInput;
