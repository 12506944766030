import React, { useState } from "react";
import ConnectDatabaseComponent from "./connect-database.component";
import { useFormik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
import useLoadingError from "src/hooks/useLoadingError";
import {
  useInsertTruckingMutation,
  useInsertLocalChargesMutation,
  useInsertPriceMutation,
  useUpdateTruckingMutation,
  useUpdateLocalChargesMutation,
  useUpdatePriceMutation,
} from "src/services/api-service/prices";
import { showToast } from "src/utils";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import { connectDatasourceSchema } from "src/validations/connect-datasource-schema";

const ConnectDatabaseContainer = () => {
  const navigate = useNavigate();
  const [insertPricing] = useInsertPriceMutation();
  const [updatePricing] = useUpdatePriceMutation();
  const [insertLocalCharge] = useInsertLocalChargesMutation();
  const [updateLocalCharge] = useUpdateLocalChargesMutation();
  const [insertHub] = useInsertTruckingMutation();
  const [updateHub] = useUpdateTruckingMutation();
  const [fileList, setFileList] = useState<any[]>([]);
  const { reset, startLoading, stopLoading } = useLoadingError();

  const [url] = useSearchParams();
  const currentId = url?.get("id");
  const type = url?.get("type");
  const storedData = {
    sheet: fileList,
  };

  const formik = useFormik({
    initialValues: {
      sheet: [],
      container_type: "",
    },
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: connectDatasourceSchema,
    onSubmit: async (values) => {
      startLoading();
      const body = {
        id: currentId,
        sheet: fileList.filter((file) => file instanceof File),
        container_type: values?.container_type,
      };
      let APIHIT;
      if (type) {
        type === "pricing" && !currentId && (APIHIT = insertPricing(body));
        type === "pricing" && currentId && (APIHIT = updatePricing(body));
        type === "local-charge" &&
          !currentId &&
          (APIHIT = insertLocalCharge(body));
        type === "local-charge" &&
          currentId &&
          (APIHIT = updateLocalCharge(body));
        type === "trucking" && !currentId && (APIHIT = insertHub(body));
        type === "trucking" && currentId && (APIHIT = updateHub(body));
        APIHIT &&
          APIHIT.unwrap()
            .then((response) => {
              showToast(TOASTR_TYPES.SUCCESS, response?.message);
              setFileList([]);
              navigate(`/admin/prices`);
            })
            .catch((err: any) => {
              console.log(err);
            })
            .finally(() => {
              stopLoading();
            });
      }
    },
  });

  return (
    <ConnectDatabaseComponent
      setFileList={setFileList}
      fileList={fileList}
      formik={formik}
      resetError={reset}
      storedData={storedData}
    />
  );
};

export default ConnectDatabaseContainer;
