import React from "react";

const CardLoaderComponent = () => {
  return (
    <div className="shimmer relative mb-8 h-[203px] overflow-x-auto rounded-[10px] ">
      <div className=" absolute bottom-0 left-0 right-0 top-0 h-full"></div>
      <div className="mb-80 min-w-[1140px]"></div>
    </div>
  );
};

export default CardLoaderComponent;
