import React from "react";
import { Checkbox, Skeleton } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";

import {
  setFilteredQuotations,
  setQuotationFilters,
  setSelectedQuotations,
} from "src/store/features/quotations";
import { quotationsSelector } from "src/store/features/quotations/selectors";
import { useAppDispatch, useAppSelector } from "src/store/hook";

const SidebarFiltersComponent = ({ title, values }: any) => {
  const quotationsData = useAppSelector(quotationsSelector);
  const dispatch = useAppDispatch();

  const handleChange = (e: CheckboxChangeEvent) => {
    const value: string = e.target.value;
    const checked: boolean = e.target.checked;
    const filters: string[] = [...quotationsData.quotationFilters]; // Avoid unnecessary copying

    if (checked) {
      filters.push(value); // Add the value to filters if checked
    } else {
      const index = filters.indexOf(value);
      if (index !== -1) {
        filters.splice(index, 1); // Remove the value from filters if unchecked
      }
    }

    const filteredQuotations =
      filters.length !== 0
        ? quotationsData.quotations.filter((item: any) =>
            filters.includes(item?.mode_of_transport),
          )
        : quotationsData.quotations;

    dispatch(setSelectedQuotations([]));
    dispatch(setFilteredQuotations(filteredQuotations));
    dispatch(setQuotationFilters(filters));
  };

  return (
    <>
      <div className="flex flex-col gap-y-3  px-12 py-12">
        <h4 className="text-16 font-semibold leading-24 text-primary-600">
          {title}
        </h4>
        {values?.map((e: any, idx: any) => {
          return !e ? (
            <Skeleton.Button
              key={e?.label}
              size="small"
              block={true}
              active={true}
            />
          ) : (
            <div
              className={`flex items-center gap-x-3 ${e.disabled ? "opacity-[0.60]" : ""}`}
              key={e.value}
            >
              <Checkbox
                id={`label-${e.value}`}
                onChange={handleChange}
                value={e.value}
                checked={
                  quotationsData.quotationFilters.includes(e.value) || e.checked
                }
                className={` ${e.disabled ? "pointer-events-none" : ""}`}
              />
              <label
                htmlFor={`label-${e.value}`}
                className={`${e.disabled ? "cursor-not-allowed" : "cursor-pointer"}  text-14 font-medium leading-20 text-primary-600`}
              >
                {e.label}
              </label>
            </div>
          );
        })}
      </div>
      <hr className="h-[1px] w-full bg-primary-100"></hr>
    </>
  );
};

export default SidebarFiltersComponent;
