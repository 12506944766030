import { IErrorResponse, IResponse } from "../../types";
import {
  onQueryStarted,
  transFormResponse,
  transformErrorResponse,
} from "../../api.config";
import store from "src/store";
import { rootApi } from "src/services/api";
import { ENDPOINTS } from "src/store/endpoints";
import {
  setData,
  setFilteredQuotations,
  setQuotationFilters,
  setQuotations,
} from "src/store/features/quotations";
import { setLoading } from "src/store/features/alerts";

/* ------------------------------------------------------------------------------------------------------------------------------ */
/* Quotations APIs */
/* ------------------------------------------------------------------------------------------------------------------------------ */

const APIS = rootApi.injectEndpoints({
  endpoints: (build) => ({
    generateQuotation: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const filteredData = Object.fromEntries(
          Object.entries(body).filter(([key, value]) => value !== ""),
        );

        const formData = new FormData();
        Object.entries(filteredData).forEach(([key, value]) => {
          if (value !== undefined && value !== null) {
            formData.append(key, value);
          }
        });

        return {
          url: ENDPOINTS.ADMIN.GENERATE_INTERNAL_QUOTATION,
          method: "POST",
          body: filteredData,
        };
      },
      onQueryStarted: () =>
        onQueryStarted(
          store.dispatch,
          window?.location?.pathname?.includes("/results"),
        ),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),
    getQuotations: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const filteredParams = Object.fromEntries(
          Object.entries(body).filter(([key, value]) => value !== ""),
        );
        const queryString = new URLSearchParams(filteredParams).toString();

        return {
          url: ENDPOINTS.ADMIN.GET_QUOTATION + `?${queryString}`,
          method: "GET",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),
    getBookings: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const filteredParams = Object.fromEntries(
          Object.entries(body).filter(([key, value]) => value !== ""),
        );
        const queryString = new URLSearchParams(filteredParams).toString();
        return {
          url: ENDPOINTS.CUSTOMER.GET_BOOKING + `?${queryString}`,
          method: "GET",
        };
      },

      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),
    getBookingDetails: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        return {
          url: ENDPOINTS.CUSTOMER.GET_BOOKING + `/${body.id}`,
          method: "GET",
        };
      },

      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse) =>
        transformErrorResponse(res, store.dispatch, true),
    }),
    getQuotationDetails: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        return {
          url: ENDPOINTS.ADMIN.DETAILS_INTERNAL_QUOTATION + `/${body.id}`,
          method: "POST",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse) => {
        store.dispatch(setData(res?.data));
        store.dispatch(setQuotations(res?.data?.quotations));
        store.dispatch(setFilteredQuotations(res?.data?.quotations));
        store.dispatch(setQuotationFilters([]));
        store.dispatch(setLoading(false));
        return res;
      },
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),
    deleteQuotation: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        return {
          url: ENDPOINTS.ADMIN.DELETE_QUOTATION + `/${body.id}`,
          method: "DELETE",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    sendQuotationViaEmail: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        return {
          url: ENDPOINTS.ADMIN.SEND_QUOTATION_VIA_EMAIL + `${body.id}`,
          method: "POST",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),
    sendQuotationEmailByIds: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        return {
          url: ENDPOINTS.ADMIN.SEND_QUOTATION_EMAIL_BY_IDS,
          method: "POST",
          body,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    updatePrice: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        return {
          url: ENDPOINTS.ADMIN.UPDATE_PRICE + `/${body.id}`,
          method: "POST",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),
    updatePriceFcl: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        return {
          url: ENDPOINTS.ADMIN.UPDATE_PRICE_FCL + `/${body.quotation_id}`,
          method: "POST",
          body: body,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    confirmQuotationForm: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const formData = new FormData();
        formData.append("shipper_customer", body?.shipper_customer);
        formData.append("shipper_name", body?.shipper_name);
        formData.append("address", body?.address);
        formData.append("shipper_reference", body?.shipper_reference);
        formData.append("email", body?.email);
        formData.append("contact_person", body?.contact_person);
        formData.append("contact_phone", body?.contact_phone);
        formData.append("additional_info", body?.additional_info);

        if (body?.files) {
          for (const file of body.files) {
            formData.append("files[]", file);
          }
        }

        return {
          url:
            ENDPOINTS.CUSTOMER.CONFIRM_QUOTATION_FORM +
            `/${body.id}/confirm-form`,
          method: "POST",
          body: formData,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    getSubQuotation: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        return {
          url: ENDPOINTS.CUSTOMER.SUB_QUOTATION + `/${body.id}/show`,
          method: "GET",
        };
      },

      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse) =>
        transformErrorResponse(res, store.dispatch, true),
    }),
    getCustomerQuotations: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const filteredParams = Object.fromEntries(
          Object.entries(body).filter(([key, value]) => value !== ""),
        );
        const queryString = new URLSearchParams(filteredParams).toString();

        return {
          url: ENDPOINTS.CUSTOMER.GET_QUOTATION + `?${queryString}`,
          method: "GET",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),
  }),
});
export const {
  useGetBookingDetailsMutation,
  useGenerateQuotationMutation,
  useGetQuotationsMutation,
  useGetBookingsMutation,
  useGetCustomerQuotationsMutation,
  useGetQuotationDetailsMutation,
  useDeleteQuotationMutation,
  useSendQuotationViaEmailMutation,
  useUpdatePriceMutation,
  useUpdatePriceFclMutation,
  useSendQuotationEmailByIdsMutation,
  useConfirmQuotationFormMutation,
  useGetSubQuotationMutation,
} = APIS;
