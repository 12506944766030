import { logout } from "src/store/features/auth/actions";
import { rootApi } from "src/services/api";
import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "src/store/features/auth";
import authCusReducer from "src/store/features/auth/customer";
import alertsReducer from "src/store/features/alerts";
import commonReducer from "src/store/features/common";
import userReducer from "src/store/features/user";
import quotationsReducer from "src/store/features/quotations";
import quotationsFclReducer from "src/store/features/quotations-fcl";

const appReducer = combineReducers({
  auth: authReducer,
  authCus: authCusReducer,
  alerts: alertsReducer,
  common: commonReducer,
  user: userReducer,
  quotations: quotationsReducer,
  quotationsFcl: quotationsFclReducer,
  [rootApi.reducerPath]: rootApi.reducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === logout.type) {
    // Reset the state of all reducers to their initial state
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
