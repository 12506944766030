import React from "react";
import MenuComponent from "./menu.component";
import type { MenuProps } from "antd";
import { useAppDispatch, useAppSelector } from "src/store/hook";
import { userSelector } from "src/store/features/auth/selectors";
import { ENDPOINTS } from "src/store/endpoints";
import { setLoading } from "src/store/features/alerts";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import { showToast } from "src/utils";

const MenuContainer = ({ params, handleOpenDeleteModal }: any) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);
  const baseUrl = process.env.REACT_APP_API_URL;
  const downloadCustomEndpoint =
    ENDPOINTS.ADMIN.DOWNLOAD_CUSTOM_QUOTATION + params?.data?.id + "/pdf";
  const downloadInternalEndpoint =
    ENDPOINTS.ADMIN.DOWNLOAD_INTERNAL_QUOTATION + params?.data?.id;

  const downloadPdf = async () => {
    try {
      dispatch(setLoading(true));
      const token = user?.access_token;
      const downloadUrl =
        baseUrl +
        (params?.quotation_type === "custom"
          ? downloadCustomEndpoint
          : downloadInternalEndpoint);
      const response = await fetch(downloadUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const contentType = response.headers.get("content-type") ?? "";
        const contentDisposition =
          response.headers.get("Content-Disposition") ?? "";
        const filenameMatch = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
          contentDisposition,
        );
        const filename =
          filenameMatch && filenameMatch[1]
            ? filenameMatch[1].replace(/['"]/g, "")
            : "quotation.pdf";
        const message =
          response.headers.get("message") ??
          "The Pdf has been successfully downloaded";

        const blob = new Blob([await response.blob()], {
          type: contentType,
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        showToast(TOASTR_TYPES.SUCCESS, message);
      } else {
        const errorMessage = await response.json();
        showToast(TOASTR_TYPES.ERROR, `${errorMessage?.error}`);
      }
    } catch (error: any) {
      showToast(TOASTR_TYPES.ERROR, `${error.message}`);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const items: MenuProps["items"] = [
    {
      label: (
        <button className="!text-primary-10" onClick={downloadPdf}>
          Download PDF
        </button>
      ),
      key: "2",
    },
    {
      label: (
        <button
          onClick={() => handleOpenDeleteModal()}
          className="!text-error-danger-600"
        >
          Delete Quotation
        </button>
      ),
      key: "3",
    },
  ];

  return <MenuComponent items={items}></MenuComponent>;
};

export default MenuContainer;
