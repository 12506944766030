import React from "react";

const McsIcChevronRightResult = ({
  className = "",
}: {
  className?: string;
}) => {
  return (
    <svg
      className={className}
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="chevron_right_24px">
        <path
          id="icon/navigation/chevron_right_24px"
          d="M9.70687 6L8.29688 7.41L12.8769 12L8.29688 16.59L9.70687 18L15.7069 12L9.70687 6Z"
          fill="#475467"
        />
      </g>
    </svg>
  );
};

export default McsIcChevronRightResult;
