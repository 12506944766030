import React from "react";
import BreadCrumbs from "src/components/molecules/breadcrumbs";
import ProfileDropDown from "src/components/molecules/profile-dropdown";
import { IPanelLayout } from "./types";

import { Icon } from "src/components/atoms/icons";
import SideBarResults from "src/components/organisms/sidebars/sidebar-results";
import SideBarCustomer from "src/components/organisms/sidebars/sidebar-customer";

const CustomerPannelLayoutComponent = ({
  sidebarRef,
  isSidebarOpen,
  toggleSidebar,
  setIsSidebarOpen,
  children,
  breadcrumbsData,
  isResultsPage,
}: IPanelLayout) => {
  return (
    <div className="main-container bg-[#FBFBFB]">
      <div className={`relative left-0 top-0 block min-h-screen md:pl-[250px]`}>
        <div className="sticky top-0 z-[150] flex w-full flex-wrap items-center gap-y-4 border-b border-border bg-white px-24 py-13">
          <div ref={sidebarRef} className="mobile-sidebar z-[99]">
            <div
              className={`fixed left-0 top-0 z-[150] h-full w-[290px] transition-all duration-300 ease-in-out md:block md:w-[250px] ${
                isSidebarOpen ? "ml-0" : "-ml-[290px] sm:-ml-[290px]"
              } md:ml-0`}
            >
              {isResultsPage ? (
                <SideBarResults
                  isSidebarOpen={isSidebarOpen}
                  toggleSidebar={toggleSidebar}
                  setIsSidebarOpen={setIsSidebarOpen}
                />
              ) : (
                <SideBarCustomer
                  isSidebarOpen={isSidebarOpen}
                  toggleSidebar={toggleSidebar}
                  setIsSidebarOpen={setIsSidebarOpen}
                />
              )}
            </div>
            <button
              type="button"
              title="Menu"
              onClick={toggleSidebar}
              className={`relative md:hidden ${
                isSidebarOpen ? "hidden" : "block"
              }`}
            >
              <Icon.McsIcHamburgerMenu />
            </button>
          </div>

          <BreadCrumbs breadcrumbsData={breadcrumbsData} />
          <ProfileDropDown />
        </div>
        <div className="pt-10">{children}</div>
      </div>
    </div>
  );
};

export default CustomerPannelLayoutComponent;
