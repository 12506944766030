import { getCurrentDate } from "src/helpers";
import { IQuotationsFclState } from "./types";
import { createSlice } from "@reduxjs/toolkit";
import { CONSTANTS } from "src/constants";

const initialState: IQuotationsFclState = {
  quotations: [],
  data: null,
  filteredQuotations: [],
  quotationFilters: [],
  quotationValues: {
    service_type: "",
    quotation_type: "",
    date: getCurrentDate(),
    // origin_port: "PTLIS",
    origin_port: "",
    // destination_port: "AEJEA",
    destination_port: "",
    pickup_location: "",
    destination_location: "",
    customer_id: null,
    reference_no: "",
    terms: CONSTANTS.FCL.TERMS_OPTIONS[1]?.value,
    cargo_item_specs: [
      {
        container_type: "",
        quantity: "",
        weight: "",
      },
    ],
    chargesData: {
      columns: [],
      charges: [
        {
          id: 1,
          category: "Freight",
          total: 0,
          details: [
            {
              id: 1,
              name: "",
              values: [],
              isEditable: true,
            },
          ],
          isEditable: true,
        },
        {
          id: 2,
          category: "Origin",
          total: 0,
          details: [
            {
              id: 1,
              name: "",
              values: [],
              isEditable: true,
            },
          ],
          isEditable: true,
        },
        {
          id: 3,
          category: "Destination",
          total: 0,
          details: [
            {
              id: 1,
              name: "",
              values: [],
              isEditable: true,
            },
          ],
          isEditable: true,
        },
      ],
    },
  },
  isButtonDisabled: false,
  selectedQuotations: [],
  loadingFcl: false,
};

const quotationsSlice = createSlice({
  name: "quotations-fcl",
  initialState,
  reducers: {
    setQuotations(state, action) {
      state.quotations = action.payload;
    },
    setData(state, action) {
      state.data = action.payload;
    },
    setFilteredQuotations(state, action) {
      state.filteredQuotations = action.payload;
    },
    setQuotationFilters(state, action) {
      state.quotationFilters = action.payload;
    },
    setQuotationValues(state, action) {
      state.quotationValues = action.payload;
    },
    setSelectedQuotations(state, action) {
      state.selectedQuotations = action.payload;
    },
    setIsButtonDisabled(state, action) {
      state.isButtonDisabled = action.payload;
    },
    setLoadingFcl(state, action) {
      state.loadingFcl = action.payload;
    },
  },
});

export default quotationsSlice.reducer;
export const {
  setQuotations,
  setData,
  setFilteredQuotations,
  setQuotationFilters,
  setQuotationValues,
  setSelectedQuotations,
  setIsButtonDisabled,
  setLoadingFcl,
} = quotationsSlice.actions;
