import React, { useState, useEffect } from "react";
import Label from "../label";
import ErrorLabel from "../error-label";

import { Select } from "antd";
import { Icon } from "../icons";
import "./style.css";
import { ICountryPortPicker } from "src/types";
import useGetLazyPorts from "src/hooks/useGetLazyPorts";
import TextInput from "../text-input";

const LazyPortsSelector = ({
  id,
  name,
  label,
  classes,
  placeholder,
  required = false,
  errorMessage = "",
  showError,
  onSelect,
  value,
  disabled,
  allowCustomValue = false,
  onChange,
  filterValue,
}: ICountryPortPicker) => {
  const [searchValue, setSearchValue] = useState(value ?? "");

  useEffect(() => {
    setSearchValue(value ?? "");
  }, [value]);

  const { ports, loadNextPage, pagination, handleSearch } = useGetLazyPorts();

  const style = allowCustomValue
    ? { caretColor: "transparent", cursor: "pointer" }
    : {};

  if (allowCustomValue) {
    return (
      <div className={classes?.containerClassName}>
        {label && <Label id={id || name} name={label} required={required} />}

        <div className="portSelectWrapper">
          {/* <div className="prefix-icon-wrapper">{<Icon.McsIcMapPin />}</div> */}
          <TextInput
            name="email"
            type="email"
            label=""
            placeholder={placeholder}
            classes={{ containerClassName: "mb-12", inputClassName: "" }}
            value={value}
            onChange={(e: any) => {
              onChange(e.target.value);
            }}
            required={true}
            prefix={<Icon.McsIcMapPin />}
          />
        </div>

        {showError && (
          <ErrorLabel
            message={errorMessage}
            className={classes?.errorLabelClass}
          />
        )}
      </div>
    );
  }

  return (
    <div className={classes?.containerClassName}>
      {label && <Label id={id || name} name={label} required={required} />}

      <div className="portSelectWrapper">
        <div className="prefix-icon-wrapper">{<Icon.McsIcMapPin />}</div>
        <Select
          style={style}
          popupClassName="!bg-primary-50"
          rootClassName={
            allowCustomValue ? "custom-value-lazy-port-selector" : ""
          }
          placeholder={placeholder}
          size="large"
          className="w-full"
          value={value ? value : undefined}
          showSearch={true}
          disabled={disabled}
          optionLabelProp="label"
          loading={pagination?.isLoading}
          onChange={(value: any) => {
            onSelect(value);
            setSearchValue(value);
          }}
          onSearch={(value: string) => {
            setSearchValue(value);
            handleSearch(value); // Trigger backend search
          }}
          onPopupScroll={(e) => {
            const target = e.target as HTMLElement;
            if (
              target.scrollTop + target.clientHeight >= target.scrollHeight &&
              pagination.hasMore &&
              !pagination.isLoading
            ) {
              loadNextPage();
            }
          }}
          virtual={true}
          open={allowCustomValue ? false : undefined}
          allowClear={true}
        >
          {ports
            ?.filter((item: any) => item.locode !== filterValue) // Exclude items with matching locode
            .map((item: any) => (
              <Select.Option
                value={item.locode}
                key={item.locode}
                label={item.locode}
              >
                <div className="flex flex-col gap-2">
                  <span className="!text-12 !font-medium !leading-18 !text-gray-700">
                    {item?.name || ""}
                  </span>
                  <span>{`${item?.name || ""} (${item.locode})`}</span>
                </div>
              </Select.Option>
            ))}
        </Select>
      </div>

      {showError && (
        <ErrorLabel
          message={errorMessage}
          className={classes?.errorLabelClass}
        />
      )}
    </div>
  );
};

export default LazyPortsSelector;
