import React from "react";
import { Icon } from "src/components/atoms/icons";
import TextInput from "src/components/atoms/text-input";
import { useQuotationsContextFcl } from "src/context/quotations-context-fcl";
import ContainerType from "../container-type";
import { CONSTANTS } from "src/constants";
import { formatInputToTwoDecimals, sanitizeNumberInput } from "src/helpers";

const CargoItemFcl = ({ idx, removeItem }: any) => {
  const { formikQuotationFcl } = useQuotationsContextFcl();

  return (
    <div className="flex items-start justify-between lg:gap-2">
      <div className="flex w-full flex-col gap-4  py-12">
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3">
          <ContainerType idx={idx} />
          <TextInput
            name={`cargo_item_specs[${idx}].quantity`}
            type="text"
            label="No. of Containers"
            placeholder="0"
            required={true}
            maxDigits={5}
            allowNumbersOnly={true}
            classes={{
              inputClassName: "normal-input",
            }}
            onChange={formikQuotationFcl.handleChange}
            onBlur={formikQuotationFcl.handleBlur}
            errorMessage={
              formikQuotationFcl.errors.cargo_item_specs?.[idx]?.quantity
            }
            showError={Boolean(
              formikQuotationFcl.touched.cargo_item_specs?.[idx]?.quantity,
            )}
            value={
              formikQuotationFcl?.values?.cargo_item_specs?.[idx]?.quantity
            }
          />
          <TextInput
            name={`cargo_item_specs[${idx}].weight`}
            type="number"
            label="Cargo weight per container"
            required={true}
            placeholder="0.00"
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              const key = e.key;
              // Prevent '+' and '-' signs
              if (key === "+" || key === "-") {
                e.preventDefault(); // Blocks the input from being processed
              }
            }}
            onChange={(e: any) => {
              e.target.value = formatInputToTwoDecimals(e.target.value);
              formikQuotationFcl.handleChange(e);
            }}
            onBlur={formikQuotationFcl.handleBlur}
            suffix={
              CONSTANTS.UNITS.WEIGHT[CONSTANTS.MEASUREMENT_UNITS.METRIC.NAME]
            }
            errorMessage={
              formikQuotationFcl.errors.cargo_item_specs?.[idx]?.weight
            }
            showError={Boolean(
              formikQuotationFcl.touched.cargo_item_specs?.[idx]?.weight,
            )}
            value={formikQuotationFcl?.values?.cargo_item_specs?.[idx]?.weight}
          />
        </div>
      </div>

      <button
        type="button"
        className="relative top-[38px] cursor-pointer disabled:cursor-not-allowed"
        disabled={formikQuotationFcl?.values?.cargo_item_specs.length === 1}
        onClick={() => removeItem(idx)}
      >
        <Icon.McsIcCross
          fill={
            formikQuotationFcl?.values?.cargo_item_specs.length === 1
              ? "#FECDCA"
              : "#F04438"
          }
        />
      </button>
    </div>
  );
};

export default CargoItemFcl;
