import React, { useEffect, useState } from "react";

import { columns } from "./columns";
import usePagination from "src/hooks/usePagination";
import CustomDatatable from "src/components/organisms/datatable/elements";
import ConfirmDeleteComponent from "src/components/organisms/confirm-delete/confirm-delete.component";
import PopModal from "./popmodal";
import Loader from "src/components/atoms/loader";
import FilterQuotationHistory from "./filter";
import {
  useDeleteQuotationMutation,
  useGetQuotationsMutation,
} from "src/services/api-service/quotations";
import { showToast } from "src/utils";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import { TABS } from "../../quotation-tabs/types";
import { useNavigate } from "react-router-dom";

const Datatable = () => {
  const queryParams = new URLSearchParams(location.search);
  const tabValue = queryParams.get("tab") ?? TABS.HISTORY.value;
  const [updateId, setUpdateId] = useState("");
  const [deleteItem] = useDeleteQuotationMutation<any>();
  const [getQuotations] = useGetQuotationsMutation<any>();
  const navigate = useNavigate();
  const [filtersData, setFiltersData] = useState({
    mot: null,
    type: null,
    status: null,
    service_type: [],
  });

  const [isAddModalOpen, setAddModalOpen] = useState<boolean>(false);

  const handleCloseAddModal = () => {
    setAddModalOpen(false);
  };

  const {
    pagination,
    setPagination,
    handlePageChange,
    handleItemsPerPageChange,
    data,
    handleSearch,
    clearSearch,
    fetchData,
  } = usePagination({
    key: "quotations-datatable",
    mutation: getQuotations,
    filtersData,
  });

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const [columnDefs] = useState<any[]>(columns(handleOpenDeleteModal));
  const [columnDefsFilter] = useState<any[]>([
    ...columns(handleOpenDeleteModal).map((column) => column.headerName),
  ]);

  const [columnFilter, setColumnFilter] = useState<string[]>([]);

  useEffect(() => {
    setColumnFilter([...columnDefsFilter]);
  }, []);

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleConfirmDelete = () => {
    const body = {
      id: updateId,
    };

    updateId &&
      deleteItem(body)
        .unwrap()
        .then((data: any) => {
          console.log(data);
          showToast(
            TOASTR_TYPES.SUCCESS,
            data?.message || data?.data?.message || data?.original?.message,
          );
          fetchData({ currentPage: pagination?.currentPage, query: "" });
        })
        .catch((err) => {
          console.log(err);
        });

    setDeleteModalOpen(false);
  };

  useEffect(() => {
    if (tabValue === TABS.HISTORY.value)
      fetchData({ currentPage: pagination?.currentPage, query: "" });
  }, [pagination?.currentPage, tabValue]);

  return (
    <>
      <CustomDatatable
        title={"Quotation History"}
        description={`${pagination.total} Total Quotation${pagination.total > 1 ? "s" : ""}`}
        className="date-picker"
        data={data}
        columns={columnDefs.filter((column) =>
          columnFilter.includes(column.headerName),
        )}
        placeholder="Search by number, date, origin , destination and customer"
        showHeader={true}
        showFooter={true}
        pagination={pagination}
        setPagination={setPagination}
        handleSearch={handleSearch}
        handlePageChange={handlePageChange}
        handleItemsPerPageChange={handleItemsPerPageChange}
        rowDraggable={false}
        onRowClicked={(e: any) => {
          setUpdateId(e?.data?.id);
        }}
        onCellClicked={(e: any) => {
          if (
            e?.colDef?.field !== "status" &&
            e?.colDef?.field !== "customerName" &&
            e?.colDef?.field !== ""
          ) {
            navigate(
              "/admin/quotations?tab=Generate Quotations&id=" +
                e?.data?.id +
                "&type=" +
                e?.data?.quotation_type,
            );
          }
        }}
        clearSearch={clearSearch}
        filters={
          <FilterQuotationHistory
            columnOption={columnDefsFilter}
            sendFilteredColumns={setColumnFilter}
            values={filtersData}
            setFiltersData={setFiltersData}
            isShow={false}
          />
        }
        button={
          <FilterQuotationHistory
            columnOption={columnDefsFilter}
            sendFilteredColumns={setColumnFilter}
            values={filtersData}
            setFiltersData={setFiltersData}
            isShow={true}
          />
        }
      />

      {/* Delete modal */}
      <ConfirmDeleteComponent
        title="Are you sure you want to delete this quotation?"
        message="Deleted data cannot be retrieved"
        isOpen={isDeleteModalOpen}
        toggle={handleCloseDeleteModal}
        onConfirm={handleConfirmDelete}
        onCancel={handleCloseDeleteModal}
        className="custom-delete-modal"
      />

      <Loader />

      <PopModal
        isAddModalOpen={isAddModalOpen}
        handleCloseAddModal={handleCloseAddModal}
      />
    </>
  );
};

export default Datatable;
