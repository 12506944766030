import { IErrorResponse, IResponse } from "../../types";
import {
  onQueryStarted,
  transFormResponse,
  transformErrorResponse,
} from "../../api.config";
import store from "src/store";
import { rootApi } from "src/services/api";
import { ENDPOINTS } from "src/store/endpoints";

/* ------------------------------------------------------------------------------------------------------------------------------ */
/* Prices APIs */
/* ------------------------------------------------------------------------------------------------------------------------------ */

const APIS = rootApi.injectEndpoints({
  endpoints: (build) => ({
    /* ------------------------------------------------------------------------------------------------------------------------------ */
    /* Pricing */
    /* ------------------------------------------------------------------------------------------------------------------------------ */

    getPricesList: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const filteredParams = Object.fromEntries(
          Object.entries(body).filter(([key, value]) => value !== ""),
        );
        const queryString = new URLSearchParams(filteredParams).toString();

        return {
          url: ENDPOINTS.ADMIN.PRICES_EXCEL + `?${queryString}`,
          method: "GET",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    getPriceById: build.mutation<IResponse, Partial<any>>({
      query: (data) => {
        return {
          url: ENDPOINTS.ADMIN.CRUD_PRICE + `/${data?.id}/show`,
          method: "GET",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    insertPrice: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const formData = new FormData();
        formData.append("sheet", body.sheet[0]);
        formData.append("type", "pricing");
        formData.append("container_type", body?.container_type);
        return {
          url: ENDPOINTS.ADMIN.IMPORT_PRICE_SHEET,
          method: "POST",
          body: formData,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    updatePrice: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const data = {
          title: body?.title,
          url: body?.url,
        };

        return {
          url: ENDPOINTS.ADMIN.CRUD_PRICE + `/update/${body?.id}`,
          method: "PUT",
          body: data,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    deletePrice: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        return {
          url: ENDPOINTS.ADMIN.DELETE_PRICE_SHEET + `/${body?.id}`,
          method: "DELETE",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    downloadPrice: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        return {
          url: ENDPOINTS.ADMIN.DOWNLOAD_PRICE_SHEET + `/${body?.id}`,
          method: "GET",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    /* ------------------------------------------------------------------------------------------------------------------------------ */
    /* Local Charges / Hubs */
    /* ------------------------------------------------------------------------------------------------------------------------------ */

    getLocalChargesList: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const filteredParams = Object.fromEntries(
          Object.entries(body).filter(([key, value]) => value !== ""),
        );
        const queryString = new URLSearchParams(filteredParams).toString();

        return {
          url: ENDPOINTS.ADMIN.LOCAL_EXCEL_CHARGES + `?${queryString}`,
          method: "GET",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    getLocalChargeById: build.mutation<IResponse, Partial<any>>({
      query: (data) => {
        return {
          url: ENDPOINTS.ADMIN.CRUD_LOCAL_CHARGES + `/${data?.id}/show`,
          method: "GET",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    insertLocalCharges: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const formData = new FormData();
        formData.append("type", "localCharges");
        formData.append("sheet", body.sheet[0]);
        formData.append("container_type", body?.container_type);
        return {
          url: ENDPOINTS.ADMIN.IMPORT_PRICE_SHEET,
          method: "POST",
          body: formData,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    updateLocalCharges: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const data = {
          title: body?.title,
          url: body?.url,
        };

        return {
          url: ENDPOINTS.ADMIN.CRUD_LOCAL_CHARGES + `/update/${body?.id}`,
          method: "PUT",
          body: data,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    deleteLocalCharges: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        return {
          url: ENDPOINTS.ADMIN.LOCAL_EXCEL_CHARGES + `/${body?.id}`,
          method: "DELETE",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    downloadLocalCharges: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        return {
          url: ENDPOINTS.ADMIN.DOWNLOAD_LOCAL_CHARGES + `/${body?.id}`,
          method: "GET",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    /* ------------------------------------------------------------------------------------------------------------------------------ */
    /* Trucking */
    /* ------------------------------------------------------------------------------------------------------------------------------ */

    getTruckingList: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const filteredParams = Object.fromEntries(
          Object.entries(body).filter(([key, value]) => value !== ""),
        );
        const queryString = new URLSearchParams(filteredParams).toString();

        return {
          url: ENDPOINTS.ADMIN.TRUCKING_SHEET_LIST + `?${queryString}`,
          method: "GET",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    getTruckingById: build.mutation<IResponse, Partial<any>>({
      query: (data) => {
        return {
          url: ENDPOINTS.ADMIN.CRUD_TRUCKING + `/${data?.id}/show`,
          method: "GET",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    insertTrucking: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const formData = new FormData();
        formData.append("type", "trucking");
        formData.append("sheet", body.sheet[0]);
        formData.append("container_type", body?.container_type);

        return {
          url: ENDPOINTS.ADMIN.IMPORT_TRUCKING_SHEET,
          method: "POST",
          body: formData,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    updateTrucking: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const data = {
          title: body?.title,
          url: body?.url,
        };

        return {
          url: ENDPOINTS.ADMIN.CRUD_TRUCKING + `/update/${body?.id}`,
          method: "PUT",
          body: data,
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    deleteTrucking: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        return {
          url: ENDPOINTS.ADMIN.DELETE_TRUCKING_SHEET + `/${body?.id}`,
          method: "DELETE",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    downloadTrucking: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        return {
          url: ENDPOINTS.ADMIN.DOWNLOAD_TRUCKING + `/${body?.id}`,
          method: "GET",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),
  }),
});
export const {
  useDeleteLocalChargesMutation,
  useDeletePriceMutation,
  useGetLocalChargeByIdMutation,
  useGetLocalChargesListMutation,
  useGetPriceByIdMutation,
  useGetPricesListMutation,
  useInsertLocalChargesMutation,
  useInsertPriceMutation,
  useUpdateLocalChargesMutation,
  useUpdatePriceMutation,
  useDeleteTruckingMutation,
  useDownloadTruckingMutation,
  useGetTruckingByIdMutation,
  useGetTruckingListMutation,
  useInsertTruckingMutation,
  useUpdateTruckingMutation,
  useDownloadLocalChargesMutation,
  useDownloadPriceMutation,
} = APIS;
