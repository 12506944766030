"use client";

import React, { createContext, useContext, useMemo, useState } from "react";
import { useFormik } from "formik";
import { useAppSelector } from "src/store/hook";

import { quotationsFclSelector } from "src/store/features/quotations-fcl/selectors";
import { createQuotationFclSchema } from "src/validations/create-quotation-fcl";
import { useGenerateQuotationMutation } from "src/services/api-service/quotations";
import { userCusSelector } from "src/store/features/auth/customer/selectors";
import {
  formatCargoItemsFcl,
  isAdmin,
  makeFclQuotationPayload,
} from "src/helpers";
import { useLocation, useNavigate } from "react-router-dom";
import useQueryParams from "src/hooks/useQueryParams";
import useUser from "src/hooks/useUser";
import { useQuotationsContext } from "../quotations-context";
import { useDispatch } from "react-redux";
import { setLoading } from "src/store/features/alerts";
import { ENDPOINTS } from "src/store/endpoints";
import { showToast } from "src/utils";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";

const QuotationsContextFcl = createContext<any>({
  formikQuotationFcl: null,
});

export const QuotationsContextProviderFcl = ({ children }: any) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const user = useUser();

  const dispatch = useDispatch();

  const [generateQuotationMutation] = useGenerateQuotationMutation<any>();
  const { formikQuotation } = useQuotationsContext();

  const customQuotation = async (values: any) => {
    try {
      const payload = {
        ...values,
        user_id: user.id,
        customer_id: formikQuotation?.values?.customer_id,
      };

      if (id) {
        payload.main_quotation_id = id;
      }

      const token = user?.access_token;

      const downloadUrl =
        process.env.REACT_APP_API_URL +
        ENDPOINTS.ADMIN.GENERATE_INTERNAL_QUOTATION;

      const response: any = await fetch(downloadUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const headers = [...response.headers.entries()];
        console.log("Response Headers:", headers);

        const contentType = response.headers.get("content-type") ?? "";
        const contentDisposition =
          response.headers.get("Content-Disposition") ?? "";
        const filenameMatch = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
          contentDisposition,
        );

        console.log(contentDisposition, "contentDisposition>>>>");

        const filename =
          filenameMatch && filenameMatch[1]
            ? filenameMatch[1].replace(/['"]/g, "")
            : "quotation.pdf";
        const message =
          response.headers.get("message") ??
          "The Pdf has been successfully exported";

        const blob = new Blob([await response.blob()], {
          type: contentType,
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        showToast(TOASTR_TYPES.SUCCESS, message);
      } else {
        const errorMessage = await response.json();
        showToast(TOASTR_TYPES.ERROR, `${errorMessage?.error}`);
      }
    } catch (error: any) {
      showToast(TOASTR_TYPES.ERROR, `${error.message}`);
    }
  };

  // const user = useAppSelector(userCusSelector);

  const { getQueryParam } = useQueryParams();

  const id = getQueryParam("id");

  const storeData = useAppSelector(quotationsFclSelector);

  const [initialValues] = useState({
    ...storeData.quotationValues,
  });

  const formikQuotationFcl = useFormik({
    initialValues: {
      ...initialValues,
      quotation_type: isAdmin(pathname) ? "internal" : "external",
    },
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: () =>
      createQuotationFclSchema(formikQuotationFcl?.values),
    onSubmit: handleSubmit,
  });

  function handleSubmit(values: any, { setSubmitting }: any) {
    if (values?.quotation_type == "custom") {
      setSubmitting(false);
      dispatch(setLoading(true));

      customQuotation(makeFclQuotationPayload(values))
        .then((res: any) => {
          console.log(res, "response");
        })
        .catch((err) => {
          console.log("err comes hrere>>>");
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
      return;
    }

    // values.cargo_item_specs = formatCargoItemsFcl(values.cargo_item_specs);
    if (id) {
      values.main_quotation_id = id;
    }

    const payload = makeFclQuotationPayload({
      ...values,
      user_id: user?.id,
      customer_id: formikQuotation?.values?.customer_id,
    });

    console.log(payload, "payload>>>>");
    // return;

    generateQuotationMutation(payload)
      .unwrap()
      .then((res: any) => {
        navigate(`/quotations/results?id=${res?.data?.id}`);
        if (isAdmin(pathname)) {
          navigate(`/admin/quotations/results?id=${res?.data?.id}`);
        } else {
          navigate(`/quotations/results?id=${res?.data?.id}`);
        }
        setSubmitting(false);
      })
      .catch((err) => {
        setSubmitting(false);
        // showToast(TOASTR_TYPES.ERROR, err?.message);
        console.log(err, "error>>>");
      });
  }

  const value = useMemo(
    () => ({
      formikQuotationFcl,
    }),
    [formikQuotationFcl],
  );
  return (
    <QuotationsContextFcl.Provider value={value}>
      {children}
    </QuotationsContextFcl.Provider>
  );
};

export const useQuotationsContextFcl = () => useContext(QuotationsContextFcl);
