import React from "react";

import { QuotationRoutes } from "./types";
import QuotationHorizontalSteps from "src/pages/quotation-results-customer/elements/quotation-horizontal-steps";
import { formatDateQuotationRoute, formatQuotationDate } from "src/helpers";

const QuotationRouteComponent = ({
  source,
  destination,
  date,
  mode,
  secondMode,
  isStop,
  transit,
  transitTime,
  quotationType,
  startDate,
  endDate,
}: QuotationRoutes) => {
  if (quotationType == "fcl") {
    if (transit && transit != "" && transit !== undefined) {
      transit = JSON.parse(transit);
      console.log(transit, "tarnsit");
    }

    if (isStop) {
      return (
        <div className="flex flex-1 items-center justify-between rounded-[10px] bg-white px-12 py-4">
          <div className="flex flex-col">
            <p className="text-16 font-semibold leading-24 text-gray-700 ">
              {source}
            </p>
            <p className="text-12 font-normal leading-18 text-gray-600">
              {formatQuotationDate(startDate)?.split(",")}
            </p>
          </div>
          <QuotationHorizontalSteps mode={mode} value={""} />
          {transit?.map((data: any) => {
            return (
              <>
                <div className="flex flex-col">
                  <p className="text-16 font-semibold leading-24 text-gray-700 ">
                    {data?.name}
                  </p>
                  <p className="text-12 font-normal leading-18 text-gray-600">
                    {/* {formatDateQuotationRoute(date)?.split(",")} */}
                  </p>
                </div>
                <QuotationHorizontalSteps mode={mode} value={""} />
              </>
            );
          })}

          <div>
            <p className="text-16 font-semibold leading-24 text-gray-700">
              {destination}
            </p>
            <p className="text-12 font-normal leading-18 text-gray-600">
              {formatQuotationDate(endDate)}
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="flex flex-1 items-center justify-between rounded-[10px] bg-white  px-12 py-4">
        <div className="flex flex-col">
          <p className="text-16 font-semibold leading-24 text-gray-700 ">
            {source}
          </p>
          <p className="text-12 font-normal leading-18 text-gray-600">
            {formatQuotationDate(startDate)?.split(",")}
          </p>
        </div>
        <QuotationHorizontalSteps mode={mode} value={!isStop && "Direct"} />
        <div>
          <p className="text-16 font-semibold leading-24 text-gray-700">
            {destination}
          </p>
          <p className="text-12 font-normal leading-18 text-gray-600">
            {formatQuotationDate(endDate)?.split(",")}
          </p>
        </div>
      </div>
    );
  } else {
    if (isStop) {
      return (
        <div className="flex flex-1 items-center justify-between rounded-[10px] bg-white px-12 py-4">
          <div className="flex flex-col">
            <p className="text-16 font-semibold leading-24 text-gray-700 ">
              {source}
            </p>
            <p className="text-12 font-normal leading-18 text-gray-600">
              {formatDateQuotationRoute(date)?.split(",")}
            </p>
          </div>
          <QuotationHorizontalSteps mode={mode} value={""} />
          <div className="min-w-20 flex flex-col items-center">
            <p className="text-center text-16 font-semibold leading-24 text-gray-700">
              {transit}
            </p>
            <span className=" inline-flex text-center text-12 text-gray-600 ">
              {transitTime} day{transitTime === "1" ? "" : "s"}
            </span>
          </div>
          <QuotationHorizontalSteps mode={secondMode} value={""} />
          <div>
            <p className="text-16 font-semibold leading-24 text-gray-700">
              {destination}
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="flex flex-1 items-center justify-between rounded-[10px] bg-white  px-12 py-4">
        <div className="flex flex-col">
          <p className="text-16 font-semibold leading-24 text-gray-700 ">
            {source}
          </p>
          <p className="text-12 font-normal leading-18 text-gray-600">
            {formatDateQuotationRoute(date)?.split(",")}
          </p>
        </div>
        <QuotationHorizontalSteps mode={mode} value={!isStop && "Direct"} />
        <div>
          <p className="text-16 font-semibold leading-24 text-gray-700">
            {destination}
          </p>
        </div>
      </div>
    );
  }
};

export default QuotationRouteComponent;
