import React, { useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { commonRoutes, adminRoutes, customerRoutes } from "./routes";
import AppRoute from "./routes/AppRoute";
import "react-toastify/dist/ReactToastify.css";
import ToastContainer from "./components/atoms/toast-container";
import ContextProvider from "./providers/context-provider";
import { useAppSelector } from "./store/hook";
import { alertsSelector } from "./store/features/alerts/selectors";
import AppLoader from "./components/atoms/app-loader/app-loader.component";
import useQueryParams from "./hooks/useQueryParams";

function App() {
  const alerts = useAppSelector(alertsSelector);
  const location = useLocation();
  const [url] = useSearchParams();
  const type = url?.get("type");

  useEffect(() => {
    if (location?.pathname !== "/admin/prices" && !type) {
      sessionStorage.removeItem("defaultActiveKey_Prices");
    }
  }, [alerts?.loading]);

  const { pathname } = useLocation();

  const { getQueryParam } = useQueryParams();

  const tab = getQueryParam("tab");
  const id = getQueryParam("id");

  const preventNumberInputScroll = (event: any) => {
    // Check if the event target is an input field of type number
    if (event.target.type === "number") {
      // Prevent the default scroll behavior
      event.preventDefault();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    document.addEventListener("wheel", preventNumberInputScroll, {
      passive: false,
    });
  }, []);

  return (
    <>
      {/* To not show default loader on generating quotation*/}
      {alerts?.loading && <AppLoader />}
      <ContextProvider>
        <Routes>
          {[...commonRoutes, ...adminRoutes].map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <AppRoute
                  key={route.path}
                  isAuthProtected={route.isProtected}
                  Component={route.component}
                />
              }
            ></Route>
          ))}

          {customerRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <AppRoute
                  key={route.path}
                  isAuthProtected={route.isProtected}
                  Component={route.component}
                  isCustomer
                />
              }
            ></Route>
          ))}
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
        <ToastContainer />
      </ContextProvider>
    </>
  );
}

export default App;
