import React, { useEffect, useMemo, useState } from "react";

import SiderBarHeader from "../../../molecules/sidebar-header";
import { Icon } from "src/components/atoms/icons";
import { customerSideBarData, sideBarData } from "../sidebar-main/sidebar.data";
import { NavLink } from "react-router-dom";
import SidebarFilters from "../sidebar-filters";
import { useAppSelector } from "src/store/hook";
import { quotationsSelector } from "src/store/features/quotations/selectors";
import { getFilterCountQuotation } from "src/helpers";
import {
  SIDEBAR_FILTERS_FCL,
  SIDEBAR_FILTERS_LCL,
} from "../sidebar-filters/sidebar-filters.data";
import { useQuotationsContext } from "src/context/quotations-context";

const activeClass =
  "flex items-center justify-start gap-3 px-16 py-12 transition duration-[0.4s] bg-primary-300";
const inactiveClass =
  "flex items-center justify-start gap-3 px-16 py-12 transition duration-[0.4s] hover:bg-primary-100";

const SideBarResultsComponent = ({ isSidebarOpen, toggleSidebar }: any) => {
  const quotationsData = useAppSelector(quotationsSelector);
  const formikQuotation = useQuotationsContext();

  const [quotationType, setQuotationType] = useState<string | null>(null);

  useEffect(() => {
    if (
      formikQuotation?.service_type === "fcl" ||
      quotationsData?.data?.service_type === "fcl"
    ) {
      setQuotationType("fcl");
    } else if (
      formikQuotation?.service_type === "lcl" ||
      quotationsData?.data?.service_type === "lcl"
    ) {
      setQuotationType("lcl");
    } else {
      setQuotationType(null); // Default or handle as needed
    }
  }, [formikQuotation?.values?.service_type, quotationsData?.data]); // Add dependencies

  const sidebarFilters = useMemo(() => {
    if (quotationType === "fcl") {
      return SIDEBAR_FILTERS_FCL;
    } else if (quotationType === "lcl") {
      return SIDEBAR_FILTERS_LCL;
    } else {
      return {}; // Set to an empty object when quotationType is null or undefined
    }
  }, [quotationType]);

  const sidebars = useMemo(
    () => (location.href.includes("admin") ? sideBarData : customerSideBarData),
    [location],
  );

  const [data, setData] = useState<any>({});

  useEffect(() => {
    setData(sidebarFilters);
  }, [sidebarFilters]);

  useEffect(() => {
    if (quotationType === "lcl") {
      setData({
        "Shipping Mode": [
          {
            label: `Ocean (${getFilterCountQuotation(quotationsData?.quotations, "ocean")})`,
            value: "ocean",
          },
          {
            label: `Air (${getFilterCountQuotation(quotationsData?.quotations, "air")})`,
            value: "air",
          },
          {
            label: `Rail (${getFilterCountQuotation(quotationsData?.quotations, "rail")})`,
            value: "rail",
          },
          {
            label: `Road (${getFilterCountQuotation(quotationsData?.quotations, "road")})`,
            value: "road",
          },
        ],
      });
    } else if (quotationType === "fcl") {
      // Function to count occurrences of a specific term value
      const getTermValue = (currentTerm: any) => {
        return quotationsData?.data?.terms == currentTerm
          ? quotationsData?.quotations?.length
          : 0;
      };

      setData({
        Services: [
          {
            label: `Door to door (${getTermValue("3")})`,
            value: "3",
            disabled: true, // Disable if count is 0
            checked: getTermValue("3"),
          },
          {
            label: `Door to port (${getTermValue("1")})`,
            value: "1",
            disabled: true, // Disable if checked is 0
            checked: getTermValue("1"),
          },
          {
            label: `Port to door (${getTermValue("4")})`,
            value: "4",
            disabled: true, // Disable if checked is 0
            checked: getTermValue("4"),
          },
          {
            label: `Port to port (${getTermValue("2")})`,
            value: "2",
            disabled: true, // Disable if checked is 0
            checked: getTermValue("2"),
          },
        ],
      });
    }
  }, [quotationsData?.quotations, quotationType]);

  const isLoading = false;
  return (
    <>
      <div className={`flex justify-between`}>
        <SiderBarHeader />
        {isSidebarOpen && (
          <button
            className="flex px-16 py-10 md:hidden"
            onClick={toggleSidebar}
          >
            <Icon.McsIcMenu />
          </button>
        )}
      </div>
      <div className="flex h-full w-full gap-x-2">
        <ul
          className={`theme-custom-scroll absolute left-0 h-full w-full ${location.href.includes("admin") ? "bg-primary-100" : "border-primary-300 bg-brand-color"} `}
        >
          {sidebars?.map((val, i) => (
            <li key={val.route}>
              <NavLink
                to={val.route}
                className={`${
                  location.pathname === val.route ||
                  val.matchingRoutes?.includes(location.pathname)
                    ? activeClass
                    : inactiveClass
                } group relative`}
              >
                <div
                  className={`${!location.href.includes("admin") ? "grayscale invert group-hover:grayscale group-hover:invert-0" : ""}`}
                >
                  {val.Icon}
                </div>
                <span className="absolute left-[-250px] z-20 flex h-full w-[calc(100%-52px)] items-center bg-primary-100  pl-10 transition-all duration-500 group-hover:left-[52px]">
                  {val?.label}
                </span>
              </NavLink>
            </li>
          ))}
        </ul>
        <div className="absolute left-[52px] h-full w-[calc(100%-52px)] overflow-y-auto bg-white">
          {Object.keys(data).map((val: any, idx) => {
            return <SidebarFilters key={idx} title={val} values={data[val]} />;
          })}
        </div>
      </div>
    </>
  );
};

export default SideBarResultsComponent;
