import React, { useEffect, useState } from "react";
import PanelLayout from "src/components/layouts/panel-layout";
import ContentWrapper from "src/components/layouts/content-wrapper";
import CustomLink from "src/components/atoms/custom-link";
import QuotationsResultFilters from "./elements/quotation-results-filters";
import QuotationResultsCard from "./elements/quotation-results-card";
import { IBreadcrumbItem } from "src/types";
import { useGetQuotationDetailsMutation } from "src/services/api-service/quotations";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CargoShipperSuccess from "../quotations/elements/cargo-shipper-success";
import { useQuotationsContext } from "src/context/quotations-context";
import { useAppDispatch, useAppSelector } from "src/store/hook";
import { setSelectedQuotations } from "src/store/features/quotations";
import { quotationsSelector } from "src/store/features/quotations/selectors";
import QuotationResultFiltersFcl from "../customer/quotation-results/elements/quotations-result-filters-fcl";
import QuotationResultCardFcl from "../customer/quotation-results/elements/quotations-result-card-fcl";
import { isAdmin } from "src/helpers";
import { setLoadingFcl } from "src/store/features/quotations-fcl";

const QuotationsResultComponent = () => {
  const [url] = useSearchParams();
  const id: string = url?.get("id") ?? "";

  const dispatch = useAppDispatch();

  const [getDetailsMutation] = useGetQuotationDetailsMutation<any>();
  const { formikQuotation } = useQuotationsContext();
  const [reload] = useState(false);
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const quotation = useAppSelector(quotationsSelector);

  useEffect(() => {
    getDetails();
  }, [id]);

  const getDetails = () => {
    dispatch(setLoadingFcl(true));
    id &&
      getDetailsMutation({ id })
        .unwrap()
        .finally(() => {
          dispatch(setLoadingFcl(false));
        });
  };

  const breadcrumbsData: IBreadcrumbItem[] = [
    {
      title: "Generate Quotations",
      href: "/admin/quotations?tab=Generate%20Quotations",
    },
    {
      title: "Results",
    },
  ];

  useEffect(() => {
    if (reload) getDetails();
  }, [reload]);

  useEffect(() => {
    console.log(formikQuotation?.values?.quotation_type);
    if (formikQuotation?.values?.quotation_type === "custom") {
      formikQuotation.setFieldValue("quotation_type", "internal");
      navigate("/admin/quotations?tab=Generate%20Quotations&id=" + id);
    } else {
      getDetails();
    }
  }, [success]);
  useEffect(() => {
    dispatch(setSelectedQuotations([]));
  }, []);

  const href = `/admin/quotations?tab=Generate%20Quotations&id=${id}`;

  return (
    <PanelLayout isResultsPage={true} breadcrumbsData={breadcrumbsData}>
      <>
        <div className={!success ? "hidden" : ""}>
          <CargoShipperSuccess toggle={setSuccess} />
        </div>
        <ContentWrapper>
          {(quotation?.data?.service_type == "lcl" ||
            formikQuotation?.values?.service_type == "lcl") && (
            <>
              <CustomLink href={href}>Back to edit search</CustomLink>
              <QuotationsResultFilters
                getDetails={getDetails}
                isCustomer={false}
              />
              <QuotationResultsCard />
            </>
          )}

          {(quotation?.data?.service_type == "fcl" ||
            formikQuotation?.values?.service_type == "fcl") && (
            <>
              <CustomLink href={href}>Back to edit search</CustomLink>
              <QuotationResultFiltersFcl getDetails={getDetails} />
              <QuotationResultCardFcl isAdmin={isAdmin(pathname)} />
            </>
          )}
        </ContentWrapper>
      </>
    </PanelLayout>
  );
};

export default QuotationsResultComponent;
