import React from "react";
import CargoItemFcl from "../cargo-item-fcl";
import { useQuotationsContextFcl } from "src/context/quotations-context-fcl";
import { getContainerTypesCount, scrollToBottom } from "src/helpers";

const CargoSpecFcl = () => {
  const { formikQuotationFcl } = useQuotationsContextFcl();

  const addCargoItems = () => {
    formikQuotationFcl?.setValues((prev: any) => {
      return {
        ...prev,
        cargo_item_specs: [
          ...prev.cargo_item_specs,
          {
            container_type: "",
            quantity: "",
            weight: "",
          },
        ],
      };
    });

    scrollToBottom();
  };

  const removeItem = (index: number) => {
    formikQuotationFcl.setValues((prev: any) => {
      const newItems = prev.cargo_item_specs.filter(
        (item: any, i: number) => i !== index,
      );
      return {
        ...prev,
        cargo_item_specs: newItems,
      };
    });

    formikQuotationFcl.setTouched((prevTouched: any) => {
      const newTouched = prevTouched?.cargo_item_specs?.filter(
        (item: any, i: number) => i !== index,
      );
      return {
        ...prevTouched,
        cargo_item_specs: newTouched,
      };
    });

    formikQuotationFcl.setErrors((prevErrors: any) => {
      const newErrors = prevErrors?.cargo_item_specs?.filter(
        (item: any, i: number) => i !== index,
      );
      return {
        ...prevErrors,
        cargo_item_specs: newErrors,
      };
    });
  };

  return (
    <div>
      <div className="flex flex-col gap-3">
        {formikQuotationFcl?.values?.cargo_item_specs?.map(
          (item: any, idx: any) => {
            return (
              <CargoItemFcl
                idx={idx}
                key={idx}
                item={item}
                removeItem={removeItem}
              />
            );
          },
        )}

        <div>
          <button
            onClick={addCargoItems}
            className={
              getContainerTypesCount() <=
              formikQuotationFcl?.values?.cargo_item_specs?.length
                ? "hidden"
                : ""
            }
          >
            <span className="text-24 font-semibold leading-32 text-blue-300">
              +
            </span>
            <span className={"text-18 font-semibold leading-28 text-blue-300"}>
              Add another type of container
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CargoSpecFcl;
