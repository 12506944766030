import { useState, useEffect, useCallback, useRef } from "react";
import { useGetLocationsMutation } from "src/services/api-service/locations";
import { ENDPOINTS } from "src/store/endpoints";

const useGetLazyPorts = () => {
  const [ports, setPorts] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debounceTimer = useRef<NodeJS.Timeout | null>(null);

  const [pagination, setPagination] = useState({
    page: 1,
    hasMore: true,
    isLoading: false,
  });

  const [getLocations] = useGetLocationsMutation();

  const fetchData = useCallback(
    async (currentPage: number, query: string) => {
      if (pagination.isLoading || !pagination.hasMore) return;

      setPagination((prev) => ({ ...prev, isLoading: true }));

      try {
        const res: any = await getLocations({
          endpoint: ENDPOINTS.ADMIN.GET_ALL_PORTS,
          current_page: currentPage,
          search: query, // Pass search query to the API
          per_page: 30,
        }).unwrap();

        const newPorts = res || [];

        setPorts((prev) =>
          currentPage === 1 ? newPorts : [...prev, ...newPorts],
        );
        setPagination((prev) => ({
          ...prev,
          hasMore: newPorts.length > 0,
          isLoading: false,
        }));
      } catch (err) {
        console.error("Error fetching ports:", err);
        setPagination((prev) => ({
          ...prev,
          hasMore: false,
          isLoading: false,
        }));
      }
    },
    [pagination.page, searchQuery],
  );

  useEffect(() => {
    // Debounce the fetch to prevent rapid calls on search input changes
    if (debounceTimer.current) clearTimeout(debounceTimer.current);

    debounceTimer.current = setTimeout(() => {
      fetchData(pagination.page, searchQuery);
    }, 300); // Adjust the debounce time as needed

    return () => {
      if (debounceTimer.current) clearTimeout(debounceTimer.current);
    };
  }, [pagination.page, searchQuery]);

  const loadNextPage = () => {
    if (!pagination.isLoading && pagination.hasMore) {
      setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setPagination((prev) => ({ ...prev, page: 1, hasMore: true }));
  };

  return {
    ports,
    loadNextPage,
    handleSearch,
    pagination, // Return the pagination object
  };
};

export default useGetLazyPorts;
